import { Heading, Text, Button } from 'evergreen-ui'
import { Link } from 'react-router-dom'

import static_surveys from '../../../helpers/surveys.js'
import { getLogoImage } from '../../../helpers/images.js'

const SurveyCard = ({ id, count, locations, handleChangeSurvey }) => (
  <div className="surveylist_card">
    <img src={getLogoImage(id)} alt="survey_logo" />
    <Heading size={600} fontWeight={800} textTransform="uppercase">
      {static_surveys[id]}
    </Heading>
    <Text>Customer Feedback</Text>
    <span className="surveylist_card-divider" />

    <section className="surveylist_group">
      <Heading
        display="flex"
        flexDirection="column"
        alignItems="center"
        fontWeight={800}
        width="33%">
        {count}
        <Text>Responses</Text>
      </Heading>

      <Heading
        display="flex"
        flexDirection="column"
        alignItems="center"
        fontWeight={800}
        color="darkslategray"
        width="33%">
        {id}
        <Text>Survey Id</Text>
      </Heading>

      <Heading
        display="flex"
        flexDirection="column"
        alignItems="center"
        fontWeight={800}
        width="33%">
        {locations}
        <Text>Stores</Text>
      </Heading>
    </section>

    <div style={{ display: 'flex' }}>
      <Link to={`/account/dashboard`}>
        <Button
          marginTop={20}
          appearance="minimal"
          iconBefore="form"
          color="royalblue"
          height={40}
          onClick={handleChangeSurvey}>
          View Responses
        </Button>
      </Link>
    </div>
  </div>
)

export default SurveyCard
