import { useState, useEffect } from 'react'
import Charts from '../Account/Dashboard/Charts/Charts.jsx'
import dayjs from 'dayjs'

import { PrintHeader, PrintInfo } from './PrintInfo.jsx'
import static_surveys from '../../helpers/surveys.js'
import { fetchSurveyDataByToken } from '../../api/printing.js'

import { DashboardLoader, NoSurveyData } from '../../Loaders.jsx'

import '../../styles/Printing.css'

const PrintDashboard = ({ props }) => {
  const [isLoading, setLoading] = useState(true)
  const [surveyData, setSurveyData] = useState({})
  const [selectedLocation, setLocation] = useState(null)
  const [surveyId, setSurveyId] = useState(null)
  const [dates, setDates] = useState({})
  const [allStores, setAllStores] = useState(null)
  const isToken = props ? props.match.params.info : false

  useEffect(() => {
    if (isToken) {
      fetchSurveyDataByToken(isToken).then((data) => {
        setSurveyData(data.data.surveysSortedByStore)
        handleSetAllStores(data.data.surveysSortedByStore)
        setSurveyId(data.surveyId)
        setDates({ ...data.dates })
        setLoading(false)
      })
    } else {
      let storedSurveyData = localStorage.getItem('survey_data')
      if (storedSurveyData) {
        const parsedData = JSON.parse(storedSurveyData)
        setSurveyData(parsedData)
        setDates({ startDate: parsedData.startDate, endDate: parsedData.endDate })
        setLocation(parsedData.selectedLocation)
        setSurveyId(parsedData.surveyId)
      }
      setLoading(false)
    }
  }, [isToken])

  const handleSetAllStores = (surveyData) => {
    const allStoresData = Object.values(surveyData)
      .map((surveys) => surveys)
      .flat()
    setLocation('All Stores')
    return setAllStores(allStoresData)
  }

  const data = isToken ? allStores || surveyData[selectedLocation] : surveyData.data

  if (isLoading) return <DashboardLoader />
  else
    return (
      <div className="printing_wrapper animate__animated animate__fadeIn">
        <PrintHeader
          left={`${dayjs(dates.startDate).format('MM/DD/YY')} - ${dayjs(dates.endDate).format(
            'MM/DD/YY'
          )}`}
          middle={selectedLocation}
          right={`${static_surveys[surveyId]} Survey`}
        />
        <p
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            textAlign: 'center',
            marginTop: 20,
            color: 'darkslategray',
            fontWeight: 'bold',
            fontSize: 14,
            fontStyle: 'italic'
          }}>
          Viewing {data.length} surveys
        </p>
        {data ? (
          <Charts surveyData={data} isPrinting={true} surveyId={surveyId} />
        ) : (
          <NoSurveyData dates={dates} />
        )}

        <PrintInfo
          locations={isToken ? surveyData : null}
          selectedLocation={selectedLocation}
          isToken={isToken}
          changeLocation={(loc) => {
            if (loc === 'All Stores') {
              handleSetAllStores(surveyData)
            } else {
              setLocation(loc)
              return setAllStores(null)
            }
          }}
        />
      </div>
    )
}

export default PrintDashboard
