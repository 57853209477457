import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Select, Dialog, Heading, Button, toaster, AddIcon, TrashIcon } from 'evergreen-ui'

import static_surveys from '../../../helpers/surveys.js'
import { updateUser } from '../../../api/users.js'

const EditLocations = ({ isShown, handleClose, user, updateUsers }) => {
  const surveys = useSelector((state) => state.user.surveys)
  const [selectedSurvey, selectSurvey] = useState(user.surveys[0])
  const [selectedLocations, setLocations] = useState(user.locations)
  const [loading, setLoading] = useState(false)
  const locations = surveys.find((s) => s.id === selectedSurvey).stores

  const handleAddLocation = (loc) => setLocations([...selectedLocations, loc])
  const handleRemoveLocation = (loc) => setLocations(selectedLocations.filter((l) => l !== loc))

  const handleUpdateUser = () => {
    setLoading(true)
    const infoToSend = { locations: selectedLocations, user_id: user.user_id }
    updateUser(infoToSend).then(data => {
        updateUsers(data)
        toaster.success('Locations have been added to this user!')
        return handleClose()
    })
  }

  return (
    <Dialog title="Edit Account Locations" isShown={isShown} onCloseComplete={handleClose} onConfirm={handleUpdateUser} isConfirmLoading={loading}>
        <Alert
    intent="none"
    title="Adding Locations will Lock the account to only have access to the selected stores below"
    marginBottom={32}
  />
      <Select
        width={200}
        marginBottom={15}
        value={selectedSurvey}
        onChange={(e) => selectSurvey(Number(e.target.value))}>
        {user.surveys.map((id) => (
          <option value={id} key={id}>
            {static_surveys[id]}
          </option>
        ))}
      </Select>
      {locations
        .sort((a, b) => a.localeCompare(b))
        .map((loc) => (
          <Location
            key={loc}
            loc={loc}
            isSelected={selectedLocations.includes(loc)}
            handleAdd={handleAddLocation}
            handleRemove={handleRemoveLocation}
          />
        ))}
    </Dialog>
  )
}

const Location = ({ loc, isSelected, handleAdd, handleRemove }) => (
  <div key={loc} className="sidemenu_presets_location_container">
    <Heading key={loc} fontWeight={isSelected ? 600 : 200}>
      {loc}
    </Heading>
    {isSelected ? (
      <Button
        iconBefore={TrashIcon}
        appearance="minimal"
        intent="danger"
        onClick={() => handleRemove(loc)}>
        Remove
      </Button>
    ) : (
      <Button iconBefore={AddIcon} appearance="minimal" onClick={() => handleAdd(loc)}>
        Add
      </Button>
    )}
  </div>
)

export default EditLocations
