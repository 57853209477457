const axios = require('axios')
const { backendURL } = require('../config.js')

export const verifyContacts = async (dataToSend) => {
    try {
        const didVerify = await axios.post(backendURL + '/api/v2/upload/verify', dataToSend)
        if(didVerify.status === 200) return didVerify.data
        else throw new Error('Status came back as', didVerify.status)
    } catch(err) {
        const error = err.response ? err.response.data : err.message
        throw new Error(error)
    }
}

export const uploadContacts = async (client, fileName, contactListName, campaignName) => {
    try {
        const didUpload = await axios.post(backendURL + '/api/v2/upload', { client, fileName, contactListName, campaignName })
        return didUpload
    } catch(err) {
        const error = err.response ? err.response.data : err.message
        throw new Error(error)
    }
}

export const getAllReports = async () => {
    try {
        const reports = await axios.get(backendURL + '/api/v2/upload/reports')
        return reports.data
    } catch(err) {
        const error = err.response ? err.response.data : err.message
        throw new Error(error)
    }
}

export const fetchDeleteSchedule = async (client, campaignId, contactListId) => {
    try {
        const didUnSchedule = await axios.delete(backendURL + `/api/v2/upload/unschedule/${client}/${campaignId}/${contactListId}`)
        return didUnSchedule.data
    } catch(err) {
        const error = err.response ? err.response.data : err.message
        throw new Error(error)
    }
}