import { useSelector, useDispatch } from 'react-redux'
import { SET_SELECTED_LOCATION } from '../../../actions/surveys'
import Title from './Header.jsx'

const Locations = ({ onChange }) => {
  const dispatch = useDispatch()
  const locations = useSelector((state) => state.survey.allLocations)
  const selectedLocation = useSelector((state) => state.survey.selectedLocation)
  const amount = locations.length

  const selectLocation = (store) => {
    onChange()
    return dispatch(SET_SELECTED_LOCATION(store))
  }
  
  return (
    <div>
      <Title type={`SURVEY LOCATIONS (${amount})`} icon="geolocation" color="#e18a24" />
      <section style={{ marginTop: 20, marginBottom: 20 }}>
        {locations.length !== 0 ? locations
          .sort((a, b) => a.store.localeCompare(b.store, 'en', { sensitivity: 'base' }))
          .map((loc) => {
            const [city, address] = loc.store.split('-')
            return (
              <section
                key={address}
                className={getSelectedClass(selectedLocation, loc)}
                onClick={() => selectLocation(loc.store)}>
                <h1 className="sidemenu_locations_header">
                  {city}
                  <span>
                    <span>Rank #{loc.rank}</span>
                    <br />
                    {loc.surveys} surveys
                  </span>
                </h1>
                <p style={{ fontWeight: 'lighter' }}>{address}</p>
              </section>
            )
          }) : <p>No Locations Found...</p>}
      </section>
    </div>
  )
}

const getSelectedClass = (currentLoc, { store }) =>
  currentLoc === store ? 'sidemenu_locations_selected' : 'sidemenu_locations'

export default Locations
