import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Heading,
  FormIcon,
  PieChartIcon,
  ChartIcon,
  PersonIcon,
  EnvelopeIcon,
  CloudUploadIcon,
  GitRepoIcon,
  LogOutIcon,
  Icon
} from 'evergreen-ui'

import { UPDATE_TAB } from '../../actions/nav'
import { LOGOUT_USER } from '../../actions/user'

import '../../styles/NavMenu.css'

const NavMenu = () => {
  const selectedTab = useSelector((state) => state.nav.selectedTab)
  const { role } = useSelector((state) => state.user)

  return (
    <section className="navmenu_wrapper">
      <Link to="/account/surveys">
        <Tab label="surveys" icon={FormIcon} selected={selectedTab} />
      </Link>
      <Link to="/account/dashboard">
        <Tab label="dashboard" icon={PieChartIcon} selected={selectedTab} />
      </Link>
      <Link to="/account/rankings">
        <Tab label="rankings" icon={ChartIcon} selected={selectedTab} />
      </Link>

      {role === 'tog' && (
        <Link to="/account/users">
          <Tab label="accounts" icon={PersonIcon} selected={selectedTab} />
        </Link>
      )}

      <Link to="/account/reports">
        <Tab label="reports" icon={EnvelopeIcon} selected={selectedTab} />
      </Link>

      {role === 'tog' && (
        <Link to="/account/upload">
          <Tab label="upload" icon={CloudUploadIcon} selected={selectedTab} />
        </Link>
      )}

      {role === 'tog' && (
        <Link to="/account/services">
          <Tab label="services" icon={GitRepoIcon} selected={selectedTab} />
        </Link>
      )}
      {/* {role === 'tog' && <Tab label="reviews" icon={StarIcon} selected={selectedTab} />} */}

      <Tab label="logout" icon={LogOutIcon} logout />
    </section>
  )
}

const Tab = ({ selected, label, icon, logout }) => {
  const dispatch = useDispatch()
  return (
    <Heading
      size={400}
      display="flex"
      alignItems="center"
      fontWeight={selected === label ? 800 : 500}
      color={selected === label ? '#9e002f' : 'lightslategray'}
      textTransform="capitalize"
      marginBottom={40}
      className="sidenav_text"
      onClick={() => {
        if (logout) {
          dispatch(UPDATE_TAB(null))
          return dispatch(LOGOUT_USER())
        } else return dispatch(UPDATE_TAB(label))
      }}>
      <Icon icon={icon} size={28} style={{ marginRight: 15 }} />
      {label}
    </Heading>
  )
}

export default NavMenu
