import { Icon, GeolocationIcon, HomeIcon, CalendarIcon, CommentIcon } from 'evergreen-ui'
const styles = {
    top_header: {
        width: '100%',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        color: 'white'
    },
    text: { fontSize: 16, marginLeft: 15, fontWeight: 'bold', display: 'flex' },
    daterange: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20
    },
    preset: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
        boxSizing: 'border-box'
    }
}

const SideMenuTitle = ({ type, icon, color }) => (
    <section style={{ ...styles.top_header, backgroundColor: color }}>
      <h1 style={styles.text}>
        <Icon icon={getIcon(icon)} size={16} marginTop={5} marginRight={10} />
        <span style={{paddingTop: 1}}>{type}</span>
      </h1>
    </section>
  )

  const getIcon = icon => icon === 'geolocation' ? GeolocationIcon : icon === 'home' ? HomeIcon : icon === 'comment' ? CommentIcon : CalendarIcon

  export default SideMenuTitle