import { useEffect, useState } from 'react'
import { Heading, Table, BacklinkIcon, Dialog, toaster } from 'evergreen-ui'
import dayjs from 'dayjs'

import { getAllReports, fetchDeleteSchedule } from '../../../api/upload.js'

const Reports = ({ shouldUpdate }) => {
  const [reports, setReports] = useState([])
  const [filter, setFilter] = useState(null)
  const [isShown, showConfirm] = useState(false)
  const [selectedReport, selectReport] = useState({})

  useEffect(() => {
    getAllReports()
      .then((data) => setReports(data))
      .catch((err) => alert('Error getting reports...', err))
  }, [shouldUpdate])

  return (
    <div style={{ marginTop: 50, padding: 30, minWidth: 1000 }}>
      <ConfirmUnSchedule isShown={isShown} setIsShown={showConfirm} report={selectedReport} />
      <section style={{ textAlign: 'center', marginBottom: 10 }}>
        <Heading size={800}>Previous Uploads</Heading>
      </section>
      <Table>
        <Table.Head>
          <Table.SearchHeaderCell
            onChange={(value) => setFilter(value.toLowerCase())}
            placeholder="Search by client..."
          />
          <Table.TextHeaderCell flexBasis={400} flexShrink={0} flexGrow={0}>
            Campaign & List Name
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
            Contacts
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
            Date Sent
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
            Un-Schedule
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body height={250}>
          {reports
            .filter(({ client }) => (filter ? client.toLowerCase().includes(filter) : 1))
            .sort((a, b) => a.date_sent - b.date_sent)
            .map((report) => (
              <Table.Row key={report.id} style={{ backgroundColor: report.unscheduled ? 'indianred' : 'white' }}>
                <Table.TextCell style={{ textTransform: 'capitalize' }}>
                  <span style={{ fontWeight: 'bold' }}>{report.client}</span>
                </Table.TextCell>
                <Table.TextCell flexBasis={400} flexShrink={0} flexGrow={0}>
                  {report.campaign_name} - {report.list_name}
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={100}
                  flexShrink={0}
                  flexGrow={0}
                  isNumber
                  style={{ textAlign: 'center' }}>
                  {report.contacts}
                </Table.TextCell>
                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                  {dayjs(report.date_sent).format('MM/DD/YY')}
                </Table.TextCell>
                <Table.TextCell
                  flexBasis={120}
                  flexShrink={0}
                  flexGrow={0}
                  style={{ textAlign: 'center' }}>
                  <BacklinkIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      showConfirm(true)
                      selectReport(report)
                    }}
                  />
                </Table.TextCell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const ConfirmUnSchedule = ({ isShown, setIsShown, report }) => {
  return (
    <Dialog
      isShown={isShown}
      title="Un-Schedule Campaign?"
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Un-Schedule"
      onConfirm={() => {
        fetchDeleteSchedule(report.client, report.campaign_id, report.contact_list_id).then((data) => {
          report.unscheduled = true
          setIsShown(false)
          toaster.success('Campaign has been un-scheduled and removed.')
        }).catch(err => alert(err))
      }}>
      Are you sure you want to un-schedule this campaign?
      <br />
      <span style={{ fontStyle: 'italic', color: 'indianred', fontSize: 14 }}>
        *This will also delete the campaign, along with the uploaded contact list*
      </span>
      <ul>
        <li>Campaign Name: {report.campaign_name}</li>
        <li>List Name: {report.list_name}</li>
        <li style={{ textTransform: 'capitalize' }}>{report.client}</li>
      </ul>
    </Dialog>
  )
}
export default Reports
