const sortStores = (stores) =>
  stores.sort((a, b) =>
    a.netPromoter === b.netPromoter ? b.surveys - a.surveys : a.netPromoter < b.netPromoter ? 1 : -1
  )

export const formatStores = (surveysSortedByStore) => {
  const sortedSurveys = orderStoresByRank(surveysSortedByStore)
  const storesToReturn = Object.keys(sortedSurveys).map((store) => ({
    surveys: surveysSortedByStore[store].length,
    netPromoter: sortedSurveys[store],
    store
  }))
  return sortStores(storesToReturn).map((store, index) => ({
    ...store,
    rank: index + 1
  }))
}

export const orderStoresByRank = (stores) => {
  const storesToReturn = {}
  const storesByScore = {}
  Object.keys(stores).forEach((store) => {
    const surveys = stores[store]
    if (surveys.length === 0) return
    const scores = surveys.map((survey) => survey.netPromoter)
    return (storesByScore[store] = calcAverage(scores))
  })
  Object.keys(storesByScore)
    .map((store) => {
      return [store, storesByScore[store]]
    })
    .sort((a, b) => b[1] - a[1])
    .forEach((store) => (storesToReturn[store[0]] = store[1]))
  return storesToReturn
}

export const calcAverage = (responses) => {
  let total = responses.reduce((first, second) => {
    return Number(first) + Number(second)
  })
  return Number((total / responses.length).toFixed(1))
}

export const getAllStoresPreset = (locations, survey_id) => ({
  id: 'all_stores',
  name: 'All Stores',
  locations,
  survey_id
})

export const storeList = [
  {
    store_number: 3718,
    store_address: 'Ballard - 1516 E. Highway 40'
  },
  {
    store_number: 1015,
    store_address: 'Beaverton - 13325 SW Canyon Rd'
  },
  {
    store_number: 2532,
    store_address: 'Beaverton - 11150 Beaverton-Hillsdale Hwy'
  },
  {
    store_number: 2017,
    store_address: 'Bountiful - 327 W 500 S'
  },
  {
    store_number: 3730,
    store_address: 'Centerville - 300 W. Parrish Lane'
  },
  {
    store_number: 3430,
    store_address: 'Clinton - 1953 N 2000 W'
  },
  {
    store_number: 2010,
    store_address: 'Draper - 1028 E Draper Pkwy'
  },
  {
    store_number: 3442,
    store_address: 'Draper - 13955 S. Bangerter Pkwy'
  },
  {
    store_number: 1044,
    store_address: 'Gresham - 2405 SE Burnside Rd'
  },
  {
    store_number: 1014,
    store_address: 'Happy Valley - 10705 SE 82nd Ave'
  },
  {
    store_number: 2530,
    store_address: 'Happy Valley - 11965 SE 82nd Ave'
  },
  {
    store_number: 3433,
    store_address: 'Heber - 510 N. Main St'
  },
  {
    store_number: 3211,
    store_address: 'Herriman - 13154 S 5600 W'
  },
  {
    store_number: 4012,
    store_address: 'Herriman - 5146 W. Denali Park Dr.'
  },
  {
    store_number: 1013,
    store_address: 'Hillsboro - 507 SE 10th Ave'
  },
  {
    store_number: 2962,
    store_address: 'Hillsboro - 21865 NW Imbrie Dr'
  },
  {
    store_number: 3229,
    store_address: 'Kaysville - 215 W 200 N'
  },
  {
    store_number: 2014,
    store_address: 'Kearns - 6131 S Cougar Ln'
  },
  {
    store_number: 2581,
    store_address: 'Kelso - 1019 Allen St'
  },
  {
    store_number: 1919,
    store_address: 'Layton - 236 N Fairfield Rd'
  },
  {
    store_number: 2015,
    store_address: 'Layton - 1370 N Main St'
  },
  {
    store_number: 794,
    store_address: 'Longview - 962 15th Ave'
  },
  {
    store_number: 2715,
    store_address: 'Magna - 3444 S 7200 W'
  },
  {
    store_number: 3851,
    store_address: 'Midvale - 7087 S Bingham Junction Blvd'
  },
  {
    store_number: 2528,
    store_address: 'Milwaukie - 15168 SE McLoughlin Blvd'
  },
  {
    store_number: 2013,
    store_address: 'Murray - 4949 S State St'
  },
  {
    store_number: 2529,
    store_address: 'Oregon City - 19009 Beavercreek Rd'
  },
  {
    store_number: 3731,
    store_address: 'Payson - 1094 W. 800 S.'
  },
  {
    store_number: 2526,
    store_address: 'Portland - 10227 NE Halsey St'
  },
  {
    store_number: 2527,
    store_address: 'Portland - 521 NE 181st'
  },
  {
    store_number: 1011,
    store_address: 'Portland - 2025 NE Broadway St'
  },
  {
    store_number: 1010,
    store_address: 'Portland - 5545 SW Beaverton Hillsdale Hwy'
  },
  {
    store_number: 2531,
    store_address: 'Portland - 13425 NW Cornell Rd'
  },
  {
    store_number: 3436,
    store_address: 'Richfield - 955 N. Main St'
  },
  {
    store_number: 2525,
    store_address: 'Riverton - 1625 W 12600 S'
  },
  {
    store_number: 3346,
    store_address: 'Riverton - 13318 South Market Center Dr'
  },
  {
    store_number: 1224,
    store_address: 'Salt Lake City - 804 E 400 S'
  },
  {
    store_number: 2012,
    store_address: 'Salt Lake City - 1331 E Fort Union Blvd'
  },
  {
    store_number: 2021,
    store_address: 'Salt Lake City - 757 W North Temple'
  },
  {
    store_number: 2038,
    store_address: 'Salt Lake City - 677 E 400 S'
  },
  {
    store_number: 3,
    store_address: 'Salt Lake City - 9 W 3300 S'
  },
  {
    store_number: 2026,
    store_address: 'Salt Lake City - 2102 S Main St'
  },
  {
    store_number: 967,
    store_address: 'Sandy - 9045 S 255 W'
  },
  {
    store_number: 2007,
    store_address: 'Sandy - 10620 S 700 E'
  },
  {
    store_number: 2807,
    store_address: 'Sherwood - 15930 Tualatin Sherwood Rd'
  },
  {
    store_number: 3740,
    store_address: 'South Jordan - 3332 W. South Jordan Pkwy'
  },
  {
    store_number: 3434,
    store_address: 'Spanish Fork - 901 Expressway Ln'
  },
  {
    store_number: 3335,
    store_address: 'Syracuse - 1129 W 1700 S'
  },
  {
    store_number: 3732,
    store_address: 'Taylorsville - 2196 W. 5400 S.'
  },
  {
    store_number: 2039,
    store_address: 'Taylorsville - 3936 W 5400 S'
  },
  {
    store_number: 2642,
    store_address: 'Tooele - 21 W 1280 N'
  },
  {
    store_number: 2059,
    store_address: 'Vancouver - 2500 SE 165th Ave'
  },
  {
    store_number: 3013,
    store_address: 'Vancouver - 901 NE 68th St'
  },
  {
    store_number: 3701,
    store_address: 'Vancouver - 8722 NE Centerpointe Drive'
  },
  {
    store_number: 3435,
    store_address: 'Vernal - 1270 W. 400 S.'
  },
  {
    store_number: 1147,
    store_address: 'West Jordan - 1735 W 9000 S'
  },
  {
    store_number: 2036,
    store_address: 'West Jordan - 7805 S Redwood Rd'
  },
  {
    store_number: 3252,
    store_address: 'West Jordan - 7867 Airport Rd'
  },
  {
    store_number: 14,
    store_address: 'West Valley City - 1850 W 4100 S'
  },
  {
    store_number: 2008,
    store_address: 'West Valley City - 3796 W 3500 S'
  },
  {
    store_number: 3091,
    store_address: 'Wilsonville - 8815 SW Main St'
  },
  {
    store_number: 3689,
    store_address: 'Yakima - 301 S. 1st St.'
  },
  {
    store_number: 3690,
    store_address: 'Yakima - 3310 W. Nob Hill Blvd'
  },
  {
    store_number: 2316,
    store_address: '1206 Broadway Ave'
  },
  {
    store_number: 2550,
    store_address: '5007 Overland Rd'
  },
  {
    store_number: 2541,
    store_address: '1484 S. Weideman Ave'
  },
  {
    store_number: 2317,
    store_address: '3606 W. State St'
  },
  {
    store_number: 2540,
    store_address: '8018 Fairview Ave'
  },
  {
    store_number: 3762,
    store_address: '1375 SW 4th Ave'
  },
  {
    store_number: 2315,
    store_address: '1130 Caldwell Blvd'
  },
  {
    store_number: 2318,
    store_address: '824 12th Ave Rd'
  },
  {
    store_number: 3137,
    store_address: '1204 Cleveland Blvd'
  },
  {
    store_number: 2660,
    store_address: '903 NW 16th St'
  },
  {
    store_number: 2661,
    store_address: '360 E. Fairview Ave'
  },
  {
    store_number: 3240,
    store_address: '1645 S. Spring Valley Lane'
  },
  {
    store_number: 3352,
    store_address: '1516 E. State Street'
  },
  {
    store_number: 4064,
    store_address: 'South Jordan - 11333 S Redwood Rd'
  }
]
