import { useSelector, useDispatch } from 'react-redux'
import {
  FormIcon,
  CaretDownIcon,
  Avatar,
  Text,
  Popover,
  Menu,
  Select
} from 'evergreen-ui'
import { Link } from 'react-router-dom'
import TOGLogo from '../../resources/TOGSurveysLogo.png'

import { SELECT_SURVEY, SET_NOTIFICATION_SURVEYS } from '../../actions/surveys.js'
import { LOGOUT_USER, UPDATE_USER_NOTIFICATIONS } from '../../actions/user.js'
import { SELECT_PRESET } from '../../actions/presets.js'

import { NotificationBell } from './Notifications.jsx'

import static_surveys from '../../helpers/surveys.js'
import { fetchRemoveNotification } from '../../api/notifications.js'
import { getAllStoresPreset } from '../../helpers/stores.js'

import '../../styles/Header.css'

const Header = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const userSurveys = useSelector((state) => state.user.surveys)
  const { currentSurveyId: surveyId, getNotificationSurveys } = useSelector((state) => state.survey)

  const handleChangeSurvey = (e) => dispatch(SELECT_SURVEY(e.target.value))

  const handleClickNotification = (clickedSurveyId) => {
    clickedSurveyId = Number(clickedSurveyId)
    if (getNotificationSurveys !== clickedSurveyId) {
      dispatch(SELECT_SURVEY(clickedSurveyId))
      dispatch(SET_NOTIFICATION_SURVEYS(clickedSurveyId))
      const allStoresPreset = getAllStoresPreset(userSurveys.find(({ id }) => clickedSurveyId === id).stores, clickedSurveyId)
      dispatch(SELECT_PRESET(allStoresPreset))
    }
  }

  const handleDeleteNotification = (surveyId) => {
    const updatedNotifications = { ...user.notifications }
    delete updatedNotifications[surveyId]
    fetchRemoveNotification(surveyId)
    dispatch(UPDATE_USER_NOTIFICATIONS(updatedNotifications))
  }

  let notiCount = 0
  Object.values(user.notifications).forEach((count) => (notiCount += count))

  return (
    <header className="header_wrapper">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={TOGLogo} alt="Logo" className="tog_logo" />

        <section className="header_left-container">
          <FormIcon size={26} marginRight={15} color="lightslategray" />
          <section>
            <h1 className="current_survey_text">CURRENT SURVEY</h1>
            <Select
              width={160}
              height={30}
              marginRight={20}
              value={surveyId}
              onChange={handleChangeSurvey}>
              {user.surveys.map(({ id }) => (
                <option key={id} value={id}>
                  {static_surveys[id]}
                </option>
              ))}
            </Select>
          </section>
        </section>
        {/* <a
          href={`https://s.togsurveys.com/survey/?c=200238&id=${surveyId}`}
          target="_blank"
          rel="noreferrer"
          className="current_survey_text"
          style={{ display: 'flex', alignItems: 'center' }}>
          <LinkIcon marginRight={8} /> PREVIEW SURVEY
        </a> */}
      </div>

      <section className="header_right-container">
        <NotificationBell
          notis={user.notifications}
          notiCount={notiCount}
          handleClickNoti={handleClickNotification}
          handleDeleteNoti={handleDeleteNotification}
        />

        <Popover
          content={
            <Menu>
              <Menu.Group>
                {dropdowns.map((option) => (
                  <Link to={option.link} key={option.text}>
                    <Menu.Item>{option.text}</Menu.Item>
                  </Link>
                ))}
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <Menu.Item icon="log-out" intent="danger" onSelect={() => dispatch(LOGOUT_USER())}>
                  Logout
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }>
          <Text
            size={400}
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
            <Avatar
              src="https://s3-media1.fl.yelpcdn.com/bphoto/gxRZc9BNFJyJKPuLk3Zu1A/o.jpg"
              size={40}
              marginRight={10}
            />
            {user.email.split('@')[0]}
            <CaretDownIcon />
          </Text>
        </Popover>
      </section>
    </header>
  )
}

const dropdowns = [
  {
    link: '/account/surveys',
    text: 'Surveys'
  },
  {
    link: '/account/dashboard',
    text: 'Dashboard'
  },
  {
    link: '/account/edit',
    text: 'My Account'
  }
]

export default Header
