import { storeList } from './stores.js'

export const formatRankingsForCSV = (data, isUtah) => {
    console.log(data)
    return data.map(store => {
        const newRankInfo = {
            'Rank': store.rank,
            'Average Stars': store.netPromoter,
            'Store Number' : isUtah ? storeList.find((s) => store.store === s.store_address).store_number : null,
            'Store Address': store.store,
            'Surveys Taken': store.surveys,
        }
        if(!isUtah) delete newRankInfo['Store Number']
        return newRankInfo
    })
}
