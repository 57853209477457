import React from "react"
import ReactDOM from "react-dom"
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga'

import allReducers from './reducers'

import App from "./App"
import "./index.css"

ReactGA.initialize('UA-126725793-1')

const store = createStore(allReducers, applyMiddleware(thunk))

ReactDOM.render(<Provider store={store}><Router><App /></Router></Provider>, document.getElementById("root"))
