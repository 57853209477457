import React from 'react'
import { HashLoader, SquareLoader } from 'react-spinners'
import SurveySearchIcon from './resources/SurveySearch.png'

import { formatDate } from './helpers/dates.js'

const styles = {
  flex_center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontWeight: 'bold',
    color: 'darkslategray',
    margin: '10px 0 5px 0'
  },
  subheader: { fontWeight: 'lighter', color: 'lightslategray', fontSize: 14 },
  daterange: {
    marginTop: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  icon: { width: 100, marginTop: 100, marginBottom: 10 }
}

export const DashboardLoader = () => (
  <div style={{ width: '100%', height: '80vh', ...styles.flex_center }} className="animate__animated animate__fadeIn">
    <SquareLoader color="lightslategray" />
    <h1 style={styles.header}>GRABBING YOUR SURVEYS</h1>
    <p style={styles.subheader}>Please wait...</p>
  </div>
)

export const DateRangeLoader = () => (
  <div style={styles.daterange}>
    <HashLoader color="#2eb472" />
    <h1 style={styles.header}>GATHERING YOUR SURVEYS</h1>
    <p style={styles.subheader}>Please wait...</p>
  </div>
)

export const NoSurveyData = ({ dates }) => (
  <div style={{ ...styles.flex_center, marginBottom: 100 }}>
    <img src={SurveySearchIcon} style={styles.icon} alt="no surveys" />
    <h1 style={styles.header}>NO SURVEYS TAKEN</h1>
    <p style={styles.subheader}>At these locations, or</p>
    <p style={styles.subheader}>within these dates</p>
    <p style={styles.subheader}>
      {formatDate(dates.startDate)} - {formatDate(dates.endDate)}
    </p>
  </div>
)

export const NoPresetData = () => (
  <div style={{ ...styles.flex_center, marginBottom: 100 }}>
    <img src={SurveySearchIcon} style={styles.icon} alt="no surveys" />
    <h1 style={styles.header}>NO SURVEYS AT THESE LOCATIONS</h1>
    <p style={styles.subheader}>Please check your preset...</p>
  </div>
)
