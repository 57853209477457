const styles = {
	dashboard_container: {
		minWidth: 1000,
		padding: 20,
		boxSizing: 'border-box',
		width: '100%'
	},
	top_containers: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		marginBottom: 20
	},
	info_boxes_big: {
		width: '48%',
		height: 320,
		backgroundColor: 'white',
		padding: 15,
		boxSizing: 'border-box'
	},
	info_boxes_small: {
		width: '35%',
		height: '100%',
		display: 'flex'
	},
	small_box: {
		width: 320,
		height: 120,
		display: 'flex',
		backgroundColor: 'white'
	},
	survey_wrapper: {
		width: '100%',
		backgroundColor: 'white',
		padding: 15,
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'relative'
	},
	current_selected: {
		container: {
			position: 'absolute',
			top: 10,
			left: 10,
			display: 'flex',
			alignItems: 'center'
		},
		p: {
			fontWeight: 'bolder',
			fontSize: 14,
			color: 'lightslategray'
		},
		h: { fontWeight: 'lighter', fontSize: 24 }
	},
	charts: {
		container: {
			display: 'flex',
			justifyContent: 'space-around',
			width: '100%',
			height: '100%',
			flexWrap: 'wrap'
		},
		pie: (isPrinting, addPadding) => ({
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: isPrinting ? 250 : 350,
			textAlign: 'center',
			marginBottom: isPrinting && addPadding ? 20 : 30,
			// paddingBottom: isPrinting && addPadding ? 20 : 30,
			paddingBottom: isPrinting ? 50 : 20,
			borderBottom: '1px solid #52525217'
		}),
		bar: (isPrintingUtah, isPrintingFLRoberts, isPrintingGoldenNozzle) => ({
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
			// marginBottom: isPrintingUtah ? 0 : isPrintingGoldenNozzle ? 0 : 40,
			// paddingBottom: 200,
			marginTop: isPrintingFLRoberts || isPrintingUtah ? 30 : 0
		})
	},
	comments_box: {
		container: {
			width: '80%',
			borderTop: '1px solid #52525217',
			paddingTop: 40,
			height: 500
		},
		replies: {
			overflowY: 'scroll',
			height: '80%',
			marginTop: 20,
			width: '100%'
		},
		filter: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between'
		}
	}
}

export default styles