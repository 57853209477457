import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading } from 'evergreen-ui'

import { SELECT_SURVEY } from '../../../actions/surveys.js'
import { UPDATE_TAB } from '../../../actions/nav.js'

import SurveyCard from './SurveyCard'

import '../../../styles/SurveyList.css'

const SurveyList = () => {
  const dispatch = useDispatch()
  const userSurveys = useSelector((state) => state.user.surveys)

  useEffect(() => dispatch(UPDATE_TAB('surveys')))

  return (
    <div className="surveylist_wrapper">
      <Heading size={700} fontWeight={800} marginBottom={20}>
        YOUR SURVEYS
      </Heading>

      <section className="surveylist_container">
          {userSurveys.map(survey => <SurveyCard {...survey} key={survey.id} handleChangeSurvey={() => dispatch(SELECT_SURVEY(survey.id))} />)}
      </section>
    </div>
  )
}

export default SurveyList
