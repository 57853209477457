import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Heading,
  Table,
  Avatar,
  EditIcon,
  PlusIcon,
  TrashIcon,
  Popover,
  toaster
} from 'evergreen-ui'

import AddUser from './AddUser.jsx'
import ViewPresets from '../SideSheets/Presets/ViewPresets.jsx'
import EditLocations from './EditLocations.jsx'

import { fetchAllUsers, deleteUser } from '../../../api/users.js'
import static_surveys from '../../../helpers/surveys.js'

const Users = () => {
  const [users, setUsers] = useState([])
  const [selectedUser, selectUser] = useState(null)
  const [filter, setFilter] = useState('')
  const [showPresets, togglePresets] = useState(false)
  const [showLocations, toggleLocations] = useState(false)

  const [showAddUser, toggleAddUser] = useState(false)
  // const { isLoading, surveyData } = useSelector((state) => state.survey)

  useEffect(() => {
    getUsers().then((users) => setUsers(users))
  }, [])

  const handleDeleteUser = (user_id) => {
    return deleteUser(user_id).then((deletedId) => {
      toaster.danger('User has been deleted.')
      return setUsers(users.filter((user) => user.user_id !== deletedId))
    })
  }

  const updateUsers = user => setUsers(users.map((u) => (u.user_id === user.user_id ? { ...u, ...user } : u)))

  return (
    <div style={{ padding: 20 }}>
      {showPresets && (
        <ViewPresets
          isShown={showPresets}
          user={selectedUser}
          handleClose={() => togglePresets(false)}
        />
      )}
      {showAddUser && (
        <AddUser
          isShown={showAddUser}
          handleClose={(_) => toggleAddUser(false)}
          addUser={(user) => setUsers([...users, user])}
          updateUsers={updateUsers}
          userToEdit={selectedUser}
        />
      )}
      {showLocations && (
        <EditLocations isShown={showLocations} handleClose={(_) => toggleLocations(false)} user={selectedUser} updateUsers={updateUsers}/>
      )}

      <section style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Heading size={700} fontWeight={800} marginBottom={20}>
          USER ACCOUNTS
        </Heading>

        <Button
          iconBefore={PlusIcon}
          appearance="primary"
          onClick={(_) => {
            selectUser(null)
            toggleAddUser(true)
          }}>
          Add User
        </Button>
      </section>

      <Table>
        <Table.Head>
          <Table.SearchHeaderCell onChange={(e) => setFilter(e)} placeholder="Search by email..." />
          <Table.TextHeaderCell>Role</Table.TextHeaderCell>
          <Table.TextHeaderCell>Surveys</Table.TextHeaderCell>
          <Table.TextHeaderCell>Presets</Table.TextHeaderCell>
          <Table.TextHeaderCell>Locations</Table.TextHeaderCell>
          <Table.TextHeaderCell>Created On</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={100} flexShrink={0} flexGrow={0}>
            Actions
          </Table.TextHeaderCell>
        </Table.Head>
        <Table.Body style={{ backgroundColor: 'white' }}>
          {users
            .filter(({ email }) => (filter ? email.toLowerCase().includes(filter) : 1))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((profile) => (
              <Table.Row key={profile.id.toString()}>
                <Table.TextCell>
                  <UserEmail email={profile.email} />
                </Table.TextCell>
                
                <Table.TextCell style={{ textTransform: 'capitalize' }}>
                  {profile.role}
                </Table.TextCell>

                <Table.TextCell>
                  {profile.surveys.map(survey => <span style={{marginRight:4}} key={survey}>{static_surveys[survey]}</span>)}
                </Table.TextCell>

                <Table.TextCell
                  isSelectable
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    selectUser(profile)
                    togglePresets(true)
                  }}>
                  {profile.presets.length === 0 ? '1' : profile.presets.length + 1}
                  <EditIcon color="muted" marginLeft={10} size={12} />
                </Table.TextCell>

                <Table.TextCell
                  isSelectable
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    selectUser(profile)
                    toggleLocations(true)
                  }}>
                  {profile.locations.length === 0 ? 'All' : profile.locations.length}
                  <EditIcon color="muted" marginLeft={10} size={12} />
                </Table.TextCell>

                <Table.TextCell>{dayjs(profile.createdAt).format( 'MM/DD/YYYY')}</Table.TextCell>

                <Table.Cell flexBasis={100} flexShrink={0} flexGrow={0}>
                  <EditIcon
                    color="info"
                    cursor="pointer"
                    onClick={() => {
                      selectUser(profile)
                      toggleAddUser(true)
                    }}
                  />
                  <Popover
                    content={
                      <div style={{ padding: 20 }}>
                        <Heading>Are you sure you want to delete?</Heading>
                        <Button
                          cursor="pointer"
                          appearance="primary"
                          intent="danger"
                          marginTop={6}
                          onClick={(_) => handleDeleteUser(profile.user_id)}>
                          Delete User
                        </Button>
                      </div>
                    }>
                    <TrashIcon marginLeft={15} color="indianred" cursor="pointer" />
                  </Popover>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const UserEmail = ({ email }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Avatar name={email} style={{ marginRight: 8 }} />
    {email}
  </div>
)

const getUsers = async () => {
  const users = await fetchAllUsers()
  return users
}

export default Users
