import React from 'react'
import styles from '../styles'
import { ArrowLeftIcon, ArrowRightIcon, Avatar, Button, Heading, Paragraph, Select, Text } from 'evergreen-ui'
import dayjs from 'dayjs'
import StarRatings from 'react-star-ratings'

import * as relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(relativeTime)

const keysToFilter = ['first_name', 'last_name', 'email', 'netPromoter', 'takenOn', 'id']

const printingStyles = (isPrinting) => (isPrinting ? { width: '100%' } : {})

class Comments extends React.PureComponent {
  state = { filter: 'recent' }

  render() {
    const { filter } = this.state
    const {
      data,
      handleViewSingleSurvey,
      handleReset,
      isViewingSingleSurvey,
      starCount,
      isPrinting
    } = this.props
    const formattedData = filterComments(formatComments(data), filter)
    return (
      <section
        style={{
          ...styles.info_boxes_big,
          ...styles.comments_box.container,
          ...printingStyles(isPrinting)
        }}>
        <div style={styles.comments_box.filter}>
          <section>
            <Heading size={700}>Comments & Other Replies</Heading>
            <Text>Additional Questions and Comments</Text>
          </section>

            <Select
              maxWidth={240}
              onChange={({ target: { value } }) => this.setState({ filter: value })}>
              <option value="recent">Most Recent</option>
              <option value="highest">Highest Rating</option>
              <option value="lowest">Lowest Rating</option>
            </Select>

        </div>

        <div style={styles.comments_box.replies} className="comments_box_scroll">
          {formattedData.map((comment, index) => (
            <div style={{ marginBottom: 20, display: 'flex' }} key={index}>
              <Avatar
                name={formatName(comment.first_name, comment.last_name, false)}
                size={40}
                marginRight={20}
              />
              <div style={{ width: '100%' }}>
                <section style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex' }}>
                    <Heading
                      marginBottom={5}
                      marginRight={20}
                      style={{ textTransform: 'capitalize' }}>
                      {formatName(comment.first_name, comment.last_name, true)}
                    </Heading>
                    <StarCounter rating={comment.netPromoter} stars={starCount} />
                  </div>
                  <Text marginLeft={20}>
                    {dayjs(comment.takenOn).format('MM/DD/YY')} -{' '}
                    {dayjs(comment.takenOn).fromNow()}
                  </Text>
                </section>
                <Paragraph marginBottom={5} key={index}>{comment.email.toLowerCase()}</Paragraph>
                {Object.keys(comment)
                  .filter((key) => !keysToFilter.includes(key))
                  .map((question, index) => (
                    <Paragraph marginBottom={5} key={index}>
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: 'lightslategray',
                          textTransform: 'capitalize'
                        }}>
                        {question}:
                      </span>{' '}
                      {question === 'images'
                        ? comment[question].map((img) => (
                            <a
                              href={'https://togsurveys.com/file_uploads/preview.php?file=' + img}
                              target="__blank"
                              style={{ textDecoration: 'underline', marginRight: 8 }}>
                              {img}
                            </a>
                          ))
                        : comment[question]}
                    </Paragraph>
                  ))}
                {!isPrinting ? (
                  isViewingSingleSurvey ? (
                    <Button
                      appearance="minimal"
                      iconBefore="arrow-left"
                      marginLeft={-10}
                      onClick={handleReset}>
                      <ArrowLeftIcon marginRight={8} />
                      Back to Responses
                    </Button>
                  ) : (
                    <Button
                      appearance="minimal"
                      iconBefore="form"
                      marginLeft={-10}
                      onClick={() => handleViewSingleSurvey(comment.id)}>
                      View Response
                      <ArrowRightIcon marginLeft={8} />
                    </Button>
                  )
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

const formatName = (first, last, anon) =>
  first && last && first.length && last.length ? `${first} ${last}` : anon ? 'Anonymous' : '?'

const StarCounter = ({ rating, stars }) => (
  <StarRatings
    rating={Number(rating)}
    starRatedColor="goldenrod"
    numberOfStars={stars}
    starDimension="15px"
    starSpacing="4px"
  />
)

const formatComments = (data) => {
  if (!data) return []
  return data.map((oldComment) => {
    const comment = { ...oldComment }
    Object.keys(comment).forEach((field) => {
      const lowercaseField = field.toLowerCase()
      if (lowercaseField.includes('first') && lowercaseField.includes('name')) {
        comment.first_name = comment[field]
        delete comment[field]
      } else if (lowercaseField.includes('last') && lowercaseField.includes('name')) {
        comment.last_name = comment[field]
        delete comment[field]
      } else if (lowercaseField.includes('email')) {
        comment.email = comment[field]
        delete comment[field]
      } else if (lowercaseField.includes('date') && lowercaseField.includes('service')) {
        comment['date of service'] = dayjs(comment[field]).format('MM/DD/YY')
        delete comment[field]
      }
    })
    return comment
  })
}

const filterComments = (comments, filter) => {
  if (filter === 'recent') return comments.sort((a, b) => new Date(b.takenOn) - new Date(a.takenOn))
  else if (filter === 'highest') return comments.sort((a, b) => b.netPromoter - a.netPromoter)
  else if (filter === 'lowest') return comments.sort((a, b) => a.netPromoter - b.netPromoter)
  else return comments
}

export default Comments
