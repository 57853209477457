import Title from './Header.jsx'

const Responses = ({ surveys, survey, viewSingleSurvey, handleClose }) => {
  const selectedSurvey = survey ? survey.length === 1 ? survey[0].id : null : null
  return (
    <div>
      <Title
        type={`RESPONSES (${surveys ? surveys.length : 0})`}
        icon="comment"
        color="indianred"
      />
      <section style={{ marginTop: 10 }}>
        {surveys ? surveys.map((survey) => {
          let email = null
          Object.keys(survey.comments).forEach(p => {
            if(p.toLowerCase().includes('email')) email = survey.comments[p]
          })
          return (
          <div
            key={survey.id}
            className={
              selectedSurvey === survey.id ? 'sidemenu_responses_selected' : 'sidemenu_responses'
            }
            onClick={() => {
              viewSingleSurvey(survey.id)
              handleClose()
            }}>
            <h1 className="sidemenu_responses_header">
              {survey.comments['First Name'].toLowerCase() || 'Anonymous'} {survey.comments['Last Name'].toLowerCase()}
              <span>
                <span>Survey #{survey.id}</span>
                <br />
                <span>{survey.netPromoter} stars</span>
              </span>
            </h1>
            <p style={{ fontWeight: 'lighter' }}>
              {email || 'No Email'}
            </p>
          </div>)}
        ) : <p>No Responses Found...</p>}
      </section>
    </div>
  )
}

export default Responses
