import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  HomeIcon,
  CalendarIcon,
  GeolocationIcon,
  PrintIcon,
  DownloadIcon,
  CommentIcon,
  SideSheet
} from 'evergreen-ui'
import { CSVLink } from 'react-csv'
import Locations from './Locations.jsx'
import Presets from './Presets/Presets.jsx'
import DateRange from './DateRange.jsx'
import Responses from './Responses.jsx'

import '../../../styles/SideMenus.css'

const SideSheets = ({
  survey,
  allSurveys,
  isLoading,
  onChange,
  isRankings,
  dates,
  selectedLocation,
  surveyId,
  allLocations,
  csvData,
  viewSingleSurvey,
}) => {
  const [sideMenu, setSideMenu] = useState(null)
  const handleClose = () => setSideMenu(null)

  const handlePrint = () => {
    if (isRankings) {
      const dataToStore = { data: allLocations, ...dates, surveyId }
      return localStorage.setItem('rankings_data', JSON.stringify(dataToStore))
    } else {
      const dataToStore = { data: survey, ...dates, selectedLocation, surveyId }
      return localStorage.setItem('survey_data', JSON.stringify(dataToStore))
    }
  }

  return (
    <>
      <section>
        {isRankings && (
          <CSVLink data={csvData.data} filename={csvData.filename}>
            <Button
              iconBefore={DownloadIcon}
              appearance="primary"
              marginRight={10}
              disabled={isLoading}>
              Download Report
            </Button>
          </CSVLink>
        )}
        <Button
          iconBefore={CalendarIcon}
          marginRight={10}
          appearance="primary"
          intent="success"
          disabled={isLoading}
          onClick={() => setSideMenu('daterange')}>
          Date Range
        </Button>
        {!isRankings && (
          <Button
            iconBefore={HomeIcon}
            marginRight={10}
            appearance="primary"
            disabled={isLoading}
            onClick={() => setSideMenu('presets')}>
            Presets
          </Button>
        )}
        {!isRankings && (
          <Button
            iconBefore={GeolocationIcon}
            marginRight={10}
            disabled={isLoading}
            className="locations_button"
            onClick={() => setSideMenu('locations')}>
            Locations ({allLocations.length})
          </Button>
        )}
        {!isRankings && (
          <Button
            iconBefore={CommentIcon}
            marginRight={10}
            disabled={isLoading}
            className="responses_button"
            onClick={() => setSideMenu('responses')}>
            Responses ({allSurveys ? allSurveys.length : 0})
          </Button>
        )}

        <Link to={`/print/${isRankings ? 'rankings' : 'dashboard'}`} target="_blank">
          <Button
            iconBefore={PrintIcon}
            disabled={isLoading}
            onClick={handlePrint}
            appearance="minimal">
            Print
          </Button>
        </Link>
      </section>
      <SideSheet
        width={sideMenu === 'daterange' ? 600 : 300}
        isShown={sideMenu !== null}
        onCloseComplete={() => setSideMenu(null)}>
        {sideMenu === 'locations' && <Locations onChange={onChange} />}
        {sideMenu === 'presets' && <Presets onChange={onChange} />}
        {sideMenu === 'daterange' && <DateRange onChange={onChange} handleClose={handleClose} />}
        {sideMenu === 'responses' && <Responses onChange={onChange} surveys={allSurveys} survey={survey} viewSingleSurvey={viewSingleSurvey} handleClose={handleClose} />}
      </SideSheet>
    </>
  )
}

export default SideSheets
