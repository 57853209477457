import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Avatar, PlusIcon, EditIcon } from 'evergreen-ui'

import Title from '../Header.jsx'
import ManagePreset from './ManagePreset.jsx'

import { fetchAddPreset, fetchUpdatePreset, fetchDeletePreset } from '../../../../api/presets.js'
import {
  SELECT_PRESET,
  CREATE_PRESET,
  UPDATE_PRESET,
  DELETE_PRESET
} from '../../../../actions/presets'
import { getAllStoresPreset } from '../../../../helpers/stores.js'

const Presets = ({ onChange }) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const surveyId = useSelector((state) => state.survey.currentSurveyId)
  const selectedPreset = useSelector((state) => state.survey.selectedPreset)
  const presets = filterPresets(
    useSelector((state) => state.user.presets),
    surveyId
  )

  const getAllStores = () => {
    if(user.locations.length !== 0) return user.locations
    else return user.surveys.find(({ id }) => surveyId === id).stores
  }

  const allStoresPreset = getAllStoresPreset(getAllStores(), surveyId)

  //State
  const [showManagePreset, toggleManagePreset] = useState(false)
  const [presetToEdit, setPresetToEdit] = useState(null)

  const handleShowNew = () => {
    setPresetToEdit(null)
    return toggleManagePreset(true)
  }

  const handleCreatePreset = (preset) => {
    fetchAddPreset(preset)
    dispatch(CREATE_PRESET(preset))
    selectPreset(preset)
    return toggleManagePreset(false)
  }

  const handleClickEdit = (preset) => {
    setPresetToEdit(preset)
    return toggleManagePreset(true)
  }

  const selectPreset = (preset) => {
    onChange()
    return dispatch(SELECT_PRESET(preset))
  }

  const updatePreset = (preset) => {
    fetchUpdatePreset(preset)
    dispatch(UPDATE_PRESET(preset))
    if (selectedPreset && selectedPreset.id === preset.id) selectPreset(preset)
    return toggleManagePreset(false)
  }

  const deletePreset = () => {
    fetchDeletePreset(presetToEdit.id)
    if (selectedPreset && selectedPreset.id === presetToEdit.id) selectPreset(allStoresPreset)
    dispatch(DELETE_PRESET(presetToEdit.id))
    return toggleManagePreset(false)
  }

  return (
    <div>
      {showManagePreset && (
        <ManagePreset
          isShown={showManagePreset}
          presetToEdit={presetToEdit}
          updatePreset={updatePreset}
          createPreset={handleCreatePreset}
          handleDelete={deletePreset}
          handleClose={() => toggleManagePreset(false)}
          userSurveys={user.surveys.map(survey => survey.id)}
        />
      )}
      <Title type="YOUR PRESETS" icon='home' color="#0e77c9" />
      <CreatePreset showModal={handleShowNew} />
      <div style={{ marginTop: 10 }}>
        {presets
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((preset) => (
            <PresetContainer
              key={preset.id}
              {...preset}
              selected={selectedPreset ? selectedPreset.id === preset.id : null}
              handleSelect={() => selectPreset(preset)}
              handleEdit={() => handleClickEdit(preset)}
            />
          ))}
        <PresetContainer
          {...allStoresPreset}
          selected={selectedPreset ? selectedPreset.id === 'all_stores' : null}
          handleSelect={() => selectPreset(allStoresPreset)}
          handleEdit={() => alert('Cannot edit this preset')}
        />
      </div>
    </div>
  )
}

const PresetContainer = ({ id, name, locations, selected, handleSelect, handleEdit }) => (
  <section className={getPresetClass(selected)}>
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={handleSelect}>
      <Avatar color="blue" name={locations.length.toString().split('').join(' ')} size={40} />
      <h1 style={{ marginLeft: 10, fontSize: 16 }}>{name}</h1>
    </div>

    {id !== 'all_stores' && (
      <EditIcon color="muted" className="preset_edit_icon" onClick={handleEdit} />
    )}
  </section>
)

const CreatePreset = ({ showModal }) => (
  <Button
    iconBefore={PlusIcon}
    appearance="minimal"
    height={40}
    style={{ marginLeft: 10, marginTop: 10, boxShadow: 'none' }}
    onClick={showModal}>
    Create Preset
  </Button>
)

const filterPresets = (presets, surveyId) =>
  presets.filter((preset) => Number(preset.survey_id) === surveyId)

const getPresetClass = (selected) =>
  selected ? 'sidemenu_presets_selected sidemenu_presets' : 'sidemenu_presets'

export default Presets
