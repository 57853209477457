import { HomeIcon, GeolocationIcon, CalendarIcon } from 'evergreen-ui'
import { formatDate } from '../../../helpers/dates.js'
import styles from './styles'

const icon = { color: 'darkslategray', size: 20 }

const CurrentlyViewing = ({ location, dates, selectedPreset, isViewingSingleSurvey, isViewingNotis }) => (
  <>
    <section style={styles.current_selected.container}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}>
        {selectedPreset ? <HomeIcon {...icon} /> : <GeolocationIcon {...icon} />}
        <div style={{ marginLeft: 10, maxWidth: 400 }}>
          <p style={styles.current_selected.p}>
            {getLeftText(selectedPreset, isViewingSingleSurvey)}
          </p>
          <h1 style={styles.current_selected.h}>
            {getRightText(isViewingSingleSurvey, selectedPreset, location)}
          </h1>
        </div>
      </div>
    </section>

    <section style={{ position: 'absolute', top: 10, right: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
        <div style={{ marginRight: 10, maxWidth: 400 }}>
          <p style={{ ...styles.current_selected.p, textAlign: 'right' }}>{isViewingNotis ? 'Notifications' : 'Date Range'}</p>
          {isViewingNotis ? <h1 style={styles.current_selected.h}>Viewing Missed Surveys</h1> : <h1 style={styles.current_selected.h}>
            {formatDate(dates.startDate, 'MM/DD/YY')} - {formatDate(dates.endDate, 'MM/DD/YY')}
          </h1>}
        </div>
        <CalendarIcon {...icon} />
      </div>
    </section>
  </>
)

const getLeftText = (preset, singleSurvey) =>
  singleSurvey ? 'Customer Location' : preset ? 'Current Preset' : 'Currently Viewing'

const getRightText = (singleSurvey, preset, location) =>
  singleSurvey ? singleSurvey[0].locationVisited : preset ? preset.name : location

export default CurrentlyViewing
