import ReactGA from 'react-ga'

const status = {
  auth: {
    logged_in: {
      category: 'Authentication',
      action: 'Logged in!',
      label: 'Logged into the portal.'
    },
    failed_login: {
      category: 'Authentication',
      action: 'Failed attempt to log in',
      label: 'Failed Logging Into TOGSurveys'
    }
  }
}

export const GA_LoggedIn = () => ReactGA.event(status.auth.logged_in)
export const GA_FailedLogin = () => ReactGA.event(status.auth.failed_login)
