import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading } from 'evergreen-ui'

import SideSheets from '../SideSheets/SideSheets.jsx'
import RankingsTable from './RankingsTable.jsx'
import { DashboardLoader } from '../../../Loaders.jsx'

import { UPDATE_TAB } from '../../../actions/nav.js'

import { getLogoImage, getImageStyles } from '../../../helpers/images.js'
import { formatDate } from '../../../helpers/dates.js'
import { formatRankingsForCSV } from '../../../helpers/csv.js'
import static_surveys from '../../../helpers/surveys.js'
import { NoSurveyData } from '../../../Loaders.jsx'

import '../../../styles/Rankings.css'

const Rankings = () => {
  const dispatch = useDispatch()
  const { allLocations, isLoading, dates, currentSurveyId, getNotificationSurveys } = useSelector(
    (state) => state.survey
  )
  const currentSurvey = static_surveys[currentSurveyId]

  useEffect(() => {
    dispatch(UPDATE_TAB('rankings'))
  })

  const isEmpty = allLocations.length === 0
  const sortedData = allLocations.sort((a, b) => a.rank - b.rank)
  const isUtah = currentSurveyId === 21

  return (
    <div className="rankings_wrapper">
      <section>
        <RankingHeader
          dates={dates}
          surveyName={currentSurvey}
          isViewingNoti={getNotificationSurveys}
          surveyId={currentSurveyId}
        />
        <SideSheets
          isLoading={isLoading}
          isRankings
          allLocations={allLocations}
          surveyName={currentSurvey}
          csvData={{data: formatRankingsForCSV(sortedData, isUtah), filename: `TOGSurveys Rankings - ${formatDate(new Date(), 'MM-DD-YY')}.csv`}}
        />
      </section>
      {isLoading ? (
        <DashboardLoader />
      ) : isEmpty ? (
        <NoSurveyData dates={dates} />
      ) : (
        <RankingsTable sortedData={sortedData} isUtah={isUtah}/>
      )}
    </div>
  )
}

const RankingHeader = ({ surveyName, dates, isViewingNoti, surveyId }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={getLogoImage(surveyId)} alt="survey_logo" style={getImageStyles(surveyId)} />
    <section>
      <Heading marginBottom={0} size={700} fontWeight={800}>
        {surveyName.toUpperCase()} RANKINGS
      </Heading>
      <p style={{ color: 'darkslategray' }}>
        {isViewingNoti
          ? '(SURVEYS YOU MISSED)'
          : `(${formatDate(dates.startDate)} - ${formatDate(dates.endDate)})`}
      </p>
    </section>
  </div>
)

export default Rankings
