import cookies from 'react-cookies'

const userState = { isLoggedIn: false, isAuthenticating: cookies.load('isLoggedIn', false) }

const loggedReducer = (state = userState, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return { ...state, isLoggedIn: true, isAuthenticating: false, ...action.payload }
        case 'LOG_OUT':
            return { ...state, isLoggedIn: false, isAuthenticating: false }
        case 'CREATE_PRESET':
            const newPreset = action.payload
            return { ...state, presets: [...state.presets, newPreset] }
        case 'UPDATE_PRESET':
            const updatedPreset = action.payload
            return { ...state, presets: state.presets.map(p => p.id === updatedPreset.id ? updatedPreset : p) }
        case 'DELETE_PRESET':
            const presetId = action.payload
            return { ...state, presets: state.presets.filter(p => p.id !== presetId) }
        case 'UPDATE_NOTIFICATIONS':
            return { ...state, notifications: action.payload }
        default: return state
    }
}

export default loggedReducer