import axios from 'axios'

import { backendURL } from '../config.js'

const urls = {
  notifications: (survey_id) => `${backendURL}/api/v1/notifications/${survey_id}`
}

const fetchRemoveNotification = async (survey_id) => axios.delete(urls.notifications(survey_id))

export { fetchRemoveNotification }
