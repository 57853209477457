const navState = { selectedTab: 'surveys' }

const navReducer = (state = navState, action) => {
    switch(action.type) {
        case 'SET_SELECTED_TAB':
            return { ...state, selectedTab: action.payload }
        default: return state
    }
}

export default navReducer