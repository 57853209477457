import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, Route } from 'react-router-dom'

import { SET_SURVEY_DATA } from '../../actions/surveys'
import { fetchSurveyDataById } from './Dashboard/DashboardController'

import Header from '../Header/Header.jsx'
import NavMenu from '../NavMenu/NavMenu.jsx'
import SurveyList from './SurveyList/SurveyList.jsx'
import Dashboard from './Dashboard/Dashboard.jsx'
import Rankings from './Rankings/Rankings.jsx'
import Upload from './Upload/Upload.jsx'
import Users from './Users/Users.jsx'
import EditAccount from './Edit.jsx'
import Reports from './Reports.jsx'
import Services from './Services.jsx'

import '../../styles/Account.css'

const Account = ({ isLoggedIn }) => {
  const dispatch = useDispatch()
  const {currentSurveyId, surveyData, dates, getNotificationSurveys} = useSelector(state => state.survey)

  useEffect(() => {
    if(!surveyData) {
      fetchSurveyDataById(currentSurveyId, dates, getNotificationSurveys).then(data => {
        dispatch(SET_SURVEY_DATA(data))
      })
    }
  }, [dispatch, surveyData, currentSurveyId, dates, getNotificationSurveys])
  
  if (!isLoggedIn) return <Redirect to="/login" />
  return (
    <section className="account_main_page">
      <Header />
      <NavMenu />

      <section className="account_dashboard_wrapper">
        <AccountRoutes />
      </section>
    </section>
  )
}

const AccountRoutes = () => (
  <Switch>
    <Route path="/account/surveys">
      <SurveyList />
    </Route>
    <Route path="/account/dashboard">
      <Dashboard />
    </Route>
    <Route path="/account/rankings">
      <Rankings />
    </Route>
    <Route path="/account/upload">
      <Upload />
    </Route>
    <Route path='/account/reports'>
      <Reports />
    </Route>
    <Route path='/account/users'>
      <Users />
    </Route>
    <Route path="/account/edit">
      <EditAccount />
    </Route>
    <Route path='/account/services'>
      <Services />
    </Route>
  </Switch>
)

export default Account
