import { PrintIcon, WarningSignIcon, Select, ArrowDownIcon, Button } from 'evergreen-ui'
import { Link } from 'react-router-dom'

export const PrintHeader = ({ left, middle, right }) => (
  <section className='printing_header'>
    <h1>{left}</h1>
    <h1>{middle}</h1>
    <h1>{right}</h1>
  </section>
)

export const PrintInfo = ({ locations, selectedLocation, changeLocation, isToken }) => (
  <section className='printing_info'>
    {isToken && <><div>
      <Link to='/account/dashboard'><Button size="large" style={{ padding: 10 }}>Login to Dashboard</Button></Link>
      <h4 style={{color: "lightslategray", fontWeight: "normal"}}>Or</h4>
      <h3>View Different Locations</h3>
    </div>
    <ArrowDownIcon size={30} className='bob' />
    {locations && (
      <Select
        style={{ maxHeight: 35, marginBottom: 10, marginTop: 15, backgroundColor:'white' }}
        onChange={(e) => changeLocation(e.target.value)}
        value={selectedLocation}>
        <option key='all_stores' value='All Stores'>
          All Locations ({Object.keys(locations).length})
        </option>
        {Object.keys(locations).map((loc) => (
          <option key={loc} value={loc}>
            {loc} ({locations[loc].length})
          </option>
        ))}
      </Select>
    )}
    </>}
    <div onClick={window.print} className='printing_info_box'>
      <PrintIcon color='info' size={40} />
      <h1>Click Here to Print!</h1>
    </div>

    <WarningSignIcon color='warning' size={20} marginTop={50} />
    <h2>Please adjust your browser zoom level and margin settings when printing.</h2>

    <p onClick={() => window.close()}>Close this window</p>
  </section>
)
