import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextInput, EnvelopeIcon, KeyIcon } from 'evergreen-ui'
import { Redirect } from 'react-router-dom'
import {MoonLoader} from "react-spinners"

import { fetchLogin } from './LoginController'

import LoginVideo from '../../resources/LoginVideo.mp4'
import ComputerImg from '../../resources/computer_vector.png'
import TOGLogo from '../../resources/TOGSurveysLogo.png'

import '../../styles/Login.css'

const Login = () => {
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setLoading] = useState(false)

  const submitLogin = async (e) => {
    e.preventDefault()
    if(username.length === 0 || password.length === 0) return alert('Please enter your username and password...')
    setLoading(true)
    try {
      await dispatch(fetchLogin(username, password))
    } catch (err) {
      setLoading(false)
      alert(err.message)
    }
  }

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
  if (isLoggedIn) return <Redirect to="/account/dashboard" />
  return (
    <div className="login_wrapper">
      <Video />

      <div className="login_container">
        <VersionText />
        <LoginGraphic />

        <section className="login_right-container">
          <LoginLogo />

          <form className="login_form" onSubmit={submitLogin}>
            <section style={{ marginTop: 20 }}>
              <UsernameText />
              <TextInput
                placeholder="Username or Email..."
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </section>

            <section style={{ marginTop: 20 }}>
              <PasswordText />
              <TextInput
                placeholder="Password..."
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </section>

            <button type="submit">{isLoading ? <Loading /> : 'LogIn'}</button>
          </form>
        </section>
      </div>
    </div>
  )
}

const Loading = () => (
  <div style={{ display: 'table', margin: 'auto', }}>
    <MoonLoader color="white" size={16} />
  </div>
)

const Video = () => (
  <video autoPlay playsInline muted loop>
    <source autoPlay src={LoginVideo} type="video/mp4" />
  </video>
)

const LoginGraphic = () => (
  <section>
    <div className="login_gray-circle">
      <img src={ComputerImg} alt="computer" />
    </div>
  </section>
)

const LoginLogo = () => (
  <>
    <img src={TOGLogo} alt="logo" />
    <h1 className="login_text">
      Login to <span style={{ fontWeight: 'bold', color: '#98002E' }}>TOGSurveys</span>
    </h1>
  </>
)

const UsernameText = () => (
  <span className="login_input">
    <EnvelopeIcon size={14} style={{ marginRight: 5 }} />
    <p>USERNAME OR EMAIL</p>
  </span>
)

const PasswordText = () => (
  <span className="login_input">
    <KeyIcon size={14} style={{ marginRight: 5 }} />
    <p>PASSWORD</p>
  </span>
)

const VersionText = () => (
  <p className="fine_print">
    Version 2.0 - Developed by{' '}
    <a
      href="https://www.ostlergroup.com"
      rel="noopener noreferrer"
      target="_blank"
      style={{ textDecoration: 'underline' }}>
      The Ostler Group
    </a>
  </p>
)

export default Login
