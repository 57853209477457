import axios from 'axios'
import { backendURL } from '../config.js'

export const fetchUpdateUserEmails = async (mode, status) => {
    const dataToSend = { mode, status }
    try {
        const didUpdate = await axios.patch(backendURL + '/api/v1/account/updateEmails', dataToSend)
        return didUpdate.data
    } catch(err) {
        const error = err.response ? err.response.statusText : err.message
        throw new Error(error)
    }
}