import React from 'react'
import { Heading } from 'evergreen-ui'
import StarRatings from 'react-star-ratings'

import { calcAverage } from '../../../../helpers/stores.js'

const NetPromoter = ({ data, starCount, isPrinting, surveyId }) =>
  data.length ? (
    <div
      style={{
        textAlign: 'center',
        marginTop: isPrinting ? 5 : 40,
        marginBottom: isPrinting ? 30 : 40
      }}>
      <Heading fontWeight={600} size={700} marginBottom={20} marginTop={isPrinting ? 20 : 0} paddingTop={isPrinting && surveyId === 5 ? 50 : 0}>
        RATE YOUR JIFFY LUBE VISIT
      </Heading>
      <StarRatings
        rating={calcAverage(data)}
        starRatedColor={isPrinting ? 'darkslategray' : 'gold'}
        numberOfStars={starCount}
        starDimension="60px"
      />
      <Heading
        fontSize="50px"
        color={isPrinting ? 'darkslategray' : 'gold'}
        fontWeight={100}
        marginTop={20}
        marginBottom={isPrinting ? 60 : 30}>
        {calcAverage(data)}
      </Heading>
    </div>
  ) : null

export default NetPromoter
