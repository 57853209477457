import { useState } from 'react'
import { Dialog, Text, TextInputField, Switch, Checkbox, toaster } from 'evergreen-ui'

import { createUser, updateUser } from '../../../api/users.js'
import static_surveys from '../../../helpers/surveys.js'

import '../../../styles/Users.css'

const AddUser = ({ isShown, handleClose, addUser, userToEdit, updateUsers }) => {
  const [email, setEmail] = useState(userToEdit ? userToEdit.email : '')
  const [password, setPassword] = useState('')
  const [weekly, setWeekly] = useState(userToEdit ? userToEdit.weekly_emails : true)
  const [monthly, setMonthly] = useState(userToEdit ? userToEdit.monthly_emails : true)
  const [notifications, setNotifications] = useState(userToEdit ? userToEdit.notification_emails : true)
  const [surveys, setSurveys] = useState(userToEdit ? userToEdit.surveys : [])
  const [loading, setLoading] = useState(false)

  const handleAddUser = async () => {
    try {
      setLoading(true)
      const infoToSend = {
        email,
        password,
        weekly_emails: weekly,
        monthly_emails: monthly,
        notification_emails: notifications,
        surveys
      }
      if (!userToEdit) {
        const newUser = await createUser(infoToSend)
        toaster.success('User has been created!')
        addUser(newUser)
      } else {
        infoToSend.user_id = userToEdit.user_id
        const updatedUser = await updateUser(infoToSend)
        toaster.success('User has been updated!')
        updateUsers(updatedUser)
      }
      return handleClose()
    } catch(err) {
      toaster.danger(err)
      setLoading(false)
    }
  }

  return (
    <Dialog
      title={userToEdit ? 'Edit User' : 'Add a User'}
      isShown={isShown}
      onCloseComplete={handleClose}
      isConfirmDisabled={
        userToEdit ? Boolean(false) : Boolean(isDisabled(email, password, surveys))
      }
      isConfirmLoading={loading}
      onConfirm={handleAddUser}>
      <div className="adduser_wrapper">
        <section>
          <TextInputField
            value={email}
            label="Email or Username"
            placeholder="harry@mason.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </section>

        <section>
          <TextInputField
            value={password}
            label="Password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            disabled={userToEdit}
          />
        </section>
      </div>

      <div className="adduser_wrapper">
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={400} marginRight={10}>
            Weekly Emails
          </Text>
          <Switch checked={weekly} onChange={(e) => setWeekly(e.target.checked)} />
        </section>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={400} marginRight={10}>
            Monthly Emails
          </Text>
          <Switch checked={monthly} onChange={(e) => setMonthly(e.target.checked)} />
        </section>
        <section style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={400} marginRight={10}>
            Notification Emails
          </Text>
          <Switch checked={notifications} onChange={(e) => setNotifications(e.target.checked)} />
        </section>
      </div>

      {Object.keys(static_surveys).map((surveyId) => (
        <Checkbox
          key={surveyId}
          label={static_surveys[surveyId]}
          checked={surveys.includes(Number(surveyId))}
          onChange={(e) => {
            if (e.target.checked) setSurveys([...surveys, Number(surveyId)])
            else setSurveys([...surveys.filter((survey) => survey !== Number(surveyId))])
          }}
        />
      ))}
    </Dialog>
  )
}

const isDisabled = (email, pass, surveys) =>
  email.length < 3 || pass.length < 3 || surveys.length === 0

export default AddUser
