import cookies from 'react-cookies'

export const LOGIN_USER = (user) => {
    const { expires } = user
    cookies.save('isLoggedIn', true, { path: '/', maxAge: expires  })
    return { type: 'LOG_IN', payload: user }
}

export const LOGOUT_USER = () => {
    cookies.remove('isLoggedIn', { path: '/' })
    return { type: 'LOG_OUT' }
}

export const UPDATE_USER_NOTIFICATIONS = notifications => {
    return { type: 'UPDATE_NOTIFICATIONS', payload: notifications }
}
