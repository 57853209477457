import { Heading, Textarea } from 'evergreen-ui'

import DragAndDrop from './Upload/DragandDrop.jsx'

import UploadVector from '../../resources/upload_vector.svg'
import '../../styles/Upload.css'

const Services = () => {
  return (
    <div className="upload_wrapper">
      <section>
        <Heading size={800} style={{ textAlign: 'center' }}>
          Post Services <br />
          to HTML
        </Heading>
        <img src={UploadVector} alt="upload" />
      </section>

      <DragAndDrop
        setFiles={(files) => {
          files.forEach((file) => {
            const reader = new FileReader()
            reader.onload = () => {
              const csv = csvJSON(JSON.parse(JSON.stringify(reader.result)))
              document.getElementById('html_text_area').value = convertToHTML(csv)
            }
            reader.readAsText(file)
          })
        }}
      />

      <Textarea placeholder="Waiting..." width="50%" height={300} id='html_text_area' />
    </div>
  )
}

function csvJSON(csv) {
  let lines = csv.split('\n')

  let result = []

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  let headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    let obj = {}
    let currentLine = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j]
    }

    result.push(obj)
  }

  //return result; //JavaScript object
  return JSON.parse(JSON.stringify(result)) //JSON
}


const greenCheck =
  "https://cinch-prod.s3.us-west-2.amazonaws.com/company-assets/8e94cce3-9167-4f65-a0f5-74aebdd71ac0/eROC%20Checkmarks/eRoc_GreenCheck.png"

const convertToHTML = (csvData) => {
  const html = csvData.map((service) => {
    const { Service_Name, Link, Field, Filter } = service
    const regexType = selectRegexType(Field)
    const regexQuery = selectRegexQuery(regexType, Filter)

    return `{{#regex ${regexType} '${regexQuery}'}}
  <tr class="redItem">
    <td align="center">
      <img style="display:block;" height="20px" src="${greenCheck}" alt='check'>
    </td>
    <td class="serviceTitle">${Service_Name}</td>
    <td class="lastColumn"><a href="${Link}">MORE INFO</a></td>
  </tr>
{{/regex}}\n`
  })
  return html.join("").toString()
}

const selectRegexType = (regex) => {
  if (regex === 'Description') {
    return 'item_descr'
  } else if (regex === 'Item') {
    return 'item'
  } else {
    return 'item_descr'
  }
}

const selectRegexQuery = (type, filter) => {
  let updatedFilter = filter.replace(/\s/g, "").split(";").join("|")
  if (type === "item_descr") {
    if (updatedFilter.includes("%"))
      return `(${updatedFilter.replace(/%/g, "")})`
    return `(${updatedFilter})`
  } else if (type === "item") {
    if (updatedFilter.includes("%"))
      return `^(${updatedFilter.replace(/%/g, "")})`
    return `^(${updatedFilter})$`
  }
}

export default Services
