import Dropzone from 'react-dropzone'
import { Paragraph } from 'evergreen-ui'

const DragAndDrop = ({ setFiles, disabled }) => {
  const onDrop = (accepted, rejected) => {
    if(disabled) return
    setFiles(accepted)
  }
  return (
    <div style={{marginTop: -15}}>
      <Paragraph marginBottom={15} fontWeight={600}>
        CSV Drag & Drop
      </Paragraph>
      <Dropzone
        accept='.csv'
        onDrop={onDrop}
        style={{
          border: '1px dashed cadetblue',
          borderRadius: '10px',
          padding: 20,
          boxSizing: 'border-box',
          width: 300,
          cursor: 'pointer',
          transform: 'translateY(-9px)'
        }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
            color: 'cadetblue'
          }}>
          <h1 style={{ fontWeight: 'normal' }}>Click to Select</h1>
          <h1 style={{ margin: 3, fontWeight: 100 }}>or</h1>
          <h1 style={{ fontWeight: 'normal' }}>Drag and Drop</h1>
        </div>
      </Dropzone>
    </div>
  )
}

export default DragAndDrop
