import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { GridLoader } from 'react-spinners'

import { LOGIN_USER, LOGOUT_USER } from './actions/user'
import { SELECT_SURVEY } from './actions/surveys'
import { backendURL } from './config'

import './styles/Auth.css'

axios.defaults.withCredentials = true

// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     window.location.href = window.location.origin + '/login'
//     return Promise.reject(error)
//   }
// )

const Auth = () => {
  const dispatch = useDispatch()
  useEffect(() => loginWithSession(dispatch), [dispatch])

  return (
    <div className="auth_wrapper">
      <GridLoader color="lightslategray" size={22} />
      <h1 className="auth_login-text">Logging You In</h1>
      <p style={{ color: 'lightslategray' }}>Please wait...</p>
    </div>
  )
}

const loginWithSession = async (dispatch) => {
  try {
    const response = await axios.get(backendURL + '/api/v1/auth/loggedin')
    const surveyId = response.data.surveys[0].id
    dispatch(SELECT_SURVEY(surveyId))
    return dispatch(LOGIN_USER(response.data))
  } catch (err) {
    return dispatch(LOGOUT_USER())
  }
}

export default Auth
