import axios from 'axios'
import { backendURL } from '../config.js'

const preset_url = '/api/v1/user/presets'

const fetchAddPreset = async preset => {
    const didAdd = await axios.put(backendURL + preset_url, { preset })
    return didAdd
}

const fetchUpdatePreset = async preset => {
    const didUpdate = await axios.patch(backendURL + preset_url, { preset })
    return didUpdate
}

const fetchDeletePreset = async preset_id => {
    const didDelete = await axios.delete(backendURL + preset_url + '/' + preset_id)
    return didDelete
}

export { fetchAddPreset, fetchUpdatePreset, fetchDeletePreset }