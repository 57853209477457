import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateRangePicker } from 'react-date-range'
import { Button } from 'evergreen-ui'

import { SET_DATE_RANGE, SET_SURVEY_DATA } from '../../../actions/surveys'
import { fetchSurveyDataById } from '../Dashboard/DashboardController'

import Title from './Header.jsx'
import { DateRangeLoader } from '../../../Loaders.jsx'
import { formatDate } from '../../../helpers/dates.js'

const DateRange = ({ handleClose, onChange }) => {
  const dispatch = useDispatch()
  const surveyId = useSelector((state) => state.survey.currentSurveyId)
  const currentDates = useSelector((state) => state.survey.dates)
  const [isLoading, setLoading] = useState(false)
  const [dates, setDates] = useState({ ...currentDates })

  const applyDates = async () => {
    setLoading(true)
    const data = await fetchSurveyDataById(surveyId, dates)
    dispatch(SET_DATE_RANGE(dates))
    dispatch(SET_SURVEY_DATA(data))
    setLoading(false)
    if (onChange) onChange()
    return handleClose()
  }

  const { startDate, endDate } = dates
  return (
    <div className="sidemenu_daterange_container">
      <Title type="DATE RANGE FILTER" icon="calendar" color="#2eb472" />
      <section>
        <DateRangePicker
          ranges={[{ startDate, endDate, key: 'selection' }]}
          onChange={({ selection }) => setDates(selection)}
        />
      </section>
      <section className="sidemenu_date_range_info">
        <p>Dates Selected</p>
        <h3>
          {formatDate(startDate)} - {formatDate(endDate)}
        </h3>
        <div>
          <Button
            disabled={isLoading}
            iconBefore="tick"
            appearance="primary"
            intent="success"
            onClick={applyDates}>
            Apply
          </Button>
        </div>
        {isLoading && <DateRangeLoader />}
      </section>
    </div>
  )
}

export default DateRange
