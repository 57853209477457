import RankingsTable from '../Account/Rankings/RankingsTable.jsx'
import { PrintHeader, PrintInfo } from './PrintInfo.jsx'

import { formatDate } from '../../helpers/dates.js'

import '../../styles/Printing.css'

const PrintRankings = () => {
  let allLocations = localStorage.getItem('rankings_data')
  if (allLocations) allLocations = JSON.parse(allLocations)
  return (
    <div className="printing_wrapper">
      <PrintHeader
        left={`${formatDate(allLocations.startDate)} - ${formatDate(allLocations.endDate)}`}
        middle="Store Rankings"
        right={`${allLocations.surveyName} Survey`}
      />
      <RankingsTable sortedData={allLocations.data} isPrinting isUtah={allLocations.surveyName === 'Utah'}/>
      <PrintInfo />
    </div>
  )
}

export default PrintRankings
