import React from 'react'
import NetPromoter from './NetPromoter'
import Comments from './Comments'
import { Heading } from 'evergreen-ui'
import { PieChart, Pie, BarChart, Bar, Tooltip, Legend, XAxis, YAxis } from 'recharts'
import { getStarCount } from '../../../../config'

import styles from '../styles'

const Charts = ({
  isPrinting,
  surveyData,
  surveyId,
  handleReset,
  handleViewSingleSurvey,
  isViewingSingleSurvey
}) => {
  const { netPromoterData, pieData, barData, commentData } = formatSurveyResponses(surveyData)
  const starCount = getStarCount(surveyId)
  return (
    <React.Fragment>
      <NetPromoter
        data={netPromoterData}
        starCount={starCount}
        isPrinting={isPrinting}
        surveyId={surveyId}
      />
      <div style={styles.charts.container}>
        {pieData.map(({ question, data }) => (
          <PieGraph question={question} data={data} key={question} isPrinting={isPrinting} addPadding={surveyId === 11} />
        ))}
      </div>
      <div style={styles.charts.container}>
        {barData.map(({ question, data }) => (
          <BarGraph question={question} data={data} key={question} isPrintingUtah={isPrinting && surveyId === 21} isPrintingFLRoberts={isPrinting && surveyId === 9} isPrintingGoldenNozzle={isPrinting && surveyId === 5} />
        ))}
      </div>
      <Comments
        data={commentData}
        handleViewSingleSurvey={handleViewSingleSurvey}
        handleReset={handleReset}
        isViewingSingleSurvey={isViewingSingleSurvey}
        starCount={starCount}
        isPrinting={isPrinting}
      />
    </React.Fragment>
  )
}

const formatSurveyResponses = (surveyData) => {
  let netPromoterData = []
  let pieData = []
  let barData = []
  let commentData = []

  if (surveyData) {
    surveyData.forEach((data) => {
      netPromoterData.push(data.netPromoter)
      Object.keys(data.pie).forEach((question) => {
        const name = data.pie[question].toLowerCase()
        const value = 1
        const fill = name === 'yes' || name === 'average' ? 'cornflowerblue' : '#e00339'
        const shouldAdd = pieData.find((data) => data.question === question)
        if (shouldAdd) {
          const doesAnswerExist = shouldAdd.data.find((data) => data.name === name)
          doesAnswerExist
            ? (doesAnswerExist.value += value)
            : shouldAdd.data.push({ name, value, fill })
        } else pieData.push({ question, data: [{ name, value, fill }] })
      })
      Object.keys(data.bar).forEach((question) => {
        const doesQuestionExist = barData.find((data) => data.question === question)
        if (doesQuestionExist) {
          const serviceResponses = data.bar[question].map((response) => {
            return {
              service: Object.keys(response)[0],
              Yes: Object.values(response)[0] ? 1 : 0
            }
          })
          serviceResponses.forEach((answer) => {
            const shouldAdd = doesQuestionExist.data.find((data) => data.service === answer.service)
            if (shouldAdd) shouldAdd.Yes += answer.Yes
            else
              doesQuestionExist.data.push({
                service: answer.service,
                Yes: answer.Yes
              })
          })
        } else {
          const serviceResponses = data.bar[question].map((response) => {
            return {
              service: Object.keys(response)[0],
              Yes: Object.values(response)[0] ? 1 : 0
            }
          })
          barData.push({ question, data: serviceResponses })
        }
      })
      data.comments.takenOn = data.takenOn
      data.comments.netPromoter = data.netPromoter
      data.comments.id = data.id
      data.comments.location = data.locationVisited
      commentData.push(data.comments)
    })
  }

  return { netPromoterData, pieData, barData, commentData }
}

const PieGraph = ({ question, data, isPrinting, addPadding }) => (
  <div style={styles.charts.pie(isPrinting, addPadding)}>
    <Heading fontWeight={500} size={isPrinting ? 300 : 400}>
      {question}
    </Heading>

    <PieChart width={200} height={220}>
      <Pie dataKey="value" isAnimationActive={true} innerRadius={40} data={data} />
      <Tooltip />
      <Legend
        formatter={(value, entry) => (
          <span>
            {value} ({entry.payload.value})
          </span>
        )}
      />
    </PieChart>
  </div>
)

const BarGraph = ({ question, data, isPrintingUtah, isPrintingFLRoberts, isPrintingGoldenNozzle }) => (
  <div style={styles.charts.bar(isPrintingUtah, isPrintingFLRoberts, isPrintingGoldenNozzle)}>
    <Heading fontWeight={500} size={400} marginBottom={15} maxWidth={600}>
      {question}
    </Heading>

    <BarChart width={950} height={250} data={data}>
      <XAxis dataKey="service" interval={0} tick={{ fontSize: 11, width: 30 }} height={60} />
      <YAxis tick={{ fontSize: 13 }} />
      <Tooltip />
      <Bar dataKey="Yes" fill="cornflowerblue" />
    </BarChart>
    <br />
  </div>
)

export default Charts
