import { useState } from 'react'
import { Avatar, Button, Dialog, Heading, EditIcon, PlusIcon, toaster } from 'evergreen-ui'

import ManagePreset from './ManagePreset.jsx'

import { updateUserPresets } from '../../../../api/users.js'
import static_surveys from '../../../../helpers/surveys.js'

const ViewPresets = ({ user, isShown, handleClose }) => {
  const [showManage, toggleManage] = useState(false)
  const [presetToEdit, setPreset] = useState(null)

  const createPreset = preset => {
    //Add Preset to user in DB
    user.presets.push(preset)
    updatePresets(user.user_id, user.presets).then(_ => toaster.success('Preset has been created!'))
  }

  const updatePreset = preset => {
    user.presets = user.presets.map(p => p.id === preset.id ? preset : p)
    updatePresets(user.user_id, user.presets).then(_ => toaster.notify('Preset has been updated!'))
  }

  const deletePreset = () => {
    user.presets = user.presets.filter(p => p.id !== presetToEdit.id)
    updatePresets(user.user_id, user.presets).then(_ => toaster.danger('Preset has been deleted.'))
  }

  const updatePresets = async (user_id, presets) => {
    const didUpdate = await  updateUserPresets(user_id, presets)
    toggleManage(false)
    return didUpdate
  }

  return (
    <Dialog
      title={user.email + "'s Presets"}
      isShown={isShown}
      onCloseComplete={handleClose}
      footer={<Footer showCreatePreset={(_) => {
        setPreset(null)
        toggleManage(true)
      }} />}>
      {showManage && <ManagePreset isShown={showManage} userSurveys={user.surveys} createPreset={createPreset} presetToEdit={presetToEdit}  updatePreset={updatePreset} handleDelete={deletePreset} handleClose={(_) => toggleManage(false)} />}
        <div>
          {user.presets.map((preset) => (
            <section
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}
              key={preset.id}>
              <Avatar name={preset.locations.length.toString().split('').join(' ')} size={38}/>
              <Heading key={preset.name} marginLeft={10}>
                {preset.name} - {static_surveys[preset.survey_id]}
              </Heading>
              <EditIcon marginLeft={15} color='muted' style={{cursor:'pointer'}} onClick={_ => {
                setPreset(preset)
                toggleManage(true)
              }}/>
            </section>
          ))}
          <section
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}>
              <Avatar name={user.locations.length === 0 ? 'A' : user.locations.length.toString().split('').join(' ')} size={38}/>
              <Heading marginLeft={10}>
                All Stores
              </Heading>
            </section>
        </div>
    </Dialog>
  )
}

const Footer = ({ showCreatePreset }) => (
  <Button appearance="primary" iconBefore={PlusIcon} onClick={showCreatePreset}>
    Create New
  </Button>
)

export default ViewPresets
