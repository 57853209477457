import { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'

import Account from './components/Account/Account.jsx'
import Login from './components/Login/Login.jsx'
import PrintDashboard from './components/Printing/PrintDashboard.jsx'
import PrintRankings from './components/Printing/PrintRankings.jsx'
import Auth from './Auth.jsx'

import './App.css'

const App = () => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
  const isAuthenticating = useSelector((state) => state.user.isAuthenticating)
  const url = useLocation()

  useEffect(() => ReactGA.pageview(url.pathname), [url])

  if (window.location.host === 'togsurveys-v2.herokuapp.com') window.location.href = 'https://www.togsurveys.com'
  if (isAuthenticating && !window.location.pathname.includes('print')) return <Auth />
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>

      <Route path="/account">
        <Account isLoggedIn={isLoggedIn} />
      </Route>

      <Route path="/print/dashboard/:info" render={(props) => <PrintDashboard props={props} />} />
      <Route path="/print/dashboard">
        <PrintDashboard />
      </Route>

      <Route path="/print/rankings">
        <PrintRankings />
      </Route>

      <Redirect to="/login" />
    </Switch>
  )
}

export default App
