import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  SelectMenu,
  Button,
  TextInputField,
  CloudUploadIcon,
  Heading,
  Paragraph,
  CrossIcon
} from 'evergreen-ui'

import { verifyContacts, uploadContacts } from '../../../api/upload.js'
import { formatFormData } from './helpers.js'
import { UPDATE_TAB } from '../../../actions/nav.js'
import { getTodaysDate } from '../../../helpers/dates.js'
import UploadVector from '../../../resources/upload_vector.svg'

import DragandDrop from './DragandDrop.jsx'
import Reports from './Reports.jsx'
import '../../../styles/Upload.css'

const clients = ['Boise','Lube Management', 'Edwards']

const Upload = () => {
  const dispatch = useDispatch()
  const [files, setFiles] = useState([])
  const [contactListName, setContactListName] = useState(`TOGSurvey Contacts - ${getTodaysDate()}`)
  const [campaignName, setCampaignName] = useState(`TOGSurvey Campaign - ${getTodaysDate()}`)
  const [selectedClient, changeClient] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [isFinished, setFinished] = useState(false)
  const [error, setError] = useState(false)
  const [shouldGetReports, setGetReports] = useState(false)

  const [verifiedContacts, setContacts] = useState(null)

  useEffect(() => dispatch(UPDATE_TAB('upload')))

  const removeFile = (index) => {
    const arr = [...files]
    arr.splice(index, 1)
    return setFiles(arr)
  }

  const handleUpload = async () => {
    setLoading(true)
    setError(false)
    setFinished(false)
    const outter = document.getElementById('outter_loading')
    const bar = document.getElementById('inner_loading')
    const fileList = document.getElementById('file_list')
    setInitLoadingBar(bar, outter, fileList)

    const dataToSend = formatFormData(files, contactListName, campaignName, selectedClient)

    try {
      //Verify Contacts are Valid
      setTimeout(() => (bar.style.width = '30%'), 0)
      const didVerify = await verifyContacts(dataToSend)
      setContacts(didVerify.contacts)

      // Start Uploading to CC
      changeLoadingBar(bar)
      await uploadContacts(selectedClient, didVerify.fileName, contactListName, campaignName)
      setFinished(true)
      setContacts(null)
      finishLoadingBar(bar)
      outter.className = 'animate__animated animate__fadeOutUp animate__delay-4s'
      fileList.className = 'animate__animated animate__fadeOut animate__delay-4s'
      setTimeout(() => {
        setGetReports(!shouldGetReports)
        document.getElementById('completed_text').className = 'animate__animated animate__fadeOutUp animate__delay-3s'
      }, 1000)

      setTimeout(() => {
        resetLoadingBar(bar, outter, fileList)
        setFinished(false)
        setFiles([])
        setLoading(false)
      }, 5000)
    } catch (err) {
      setContacts(null)
      resetLoadingBar(bar, outter, fileList)
      setLoading(false)
      setError(err.message.toString())
    }
  }

  return (
    <div className="upload_wrapper">
      <section>
        <Heading size={800} style={{ textAlign: 'center' }}>
          Upload to <br />
          Constant Contact
        </Heading>
        <img src={UploadVector} alt="upload" />
      </section>

      <SelectMenu
        title="Select name"
        options={clients.map((label) => ({ label, value: label.toLowerCase() }))}
        selected={selectedClient}
        onSelect={(item) => changeClient(item.value)}>
        <Button height={40} style={{textTransform:'capitalize'}}>{selectedClient || 'Select a Client...'}</Button>
      </SelectMenu>

      <section>
        <div style={{ marginRight: 20 }}>
          <TextInputField
            label="Contact List Name"
            placeholder="TOGSurveys Contact List"
            value={contactListName}
            style={{ padding: 15, fontSize: 16, height: 40, width: 280 }}
            onChange={(e) => setContactListName(e.target.value)}
          />
          <TextInputField
            label="Campaign Name"
            placeholder="TOGSurveys Campaign Name"
            style={{ padding: 15, fontSize: 16, height: 40, width: 280 }}
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
        </div>

        <DragandDrop
          setFiles={(newFiles) => setFiles([...newFiles, ...files])}
          disabled={isLoading}
        />
      </section>

      <ul style={{ marginBottom: 20, listStyle: 'disc' }} id="file_list">
        {files.map((file, i) => (
          <li style={{ paddingBottom: 10, color: 'indianred', position: 'relative' }} key={i}>
            <Heading>
              {file.name} - {Math.round(Number(file.size / Math.pow(1024, 1)))} KB
            </Heading>
            {!isLoading && (
              <CrossIcon
                onClick={() => removeFile(i)}
                style={styles.crossIcon}
                className="drag_and_drop_container"
              />
            )}
          </li>
        ))}
      </ul>

      <Button
        appearance="primary"
        intent="success"
        iconBefore={CloudUploadIcon}
        disabled={selectedClient === null || files.length === 0 || isLoading}
        height={40}
        onClick={handleUpload}>
        Upload Customers
      </Button>

      <div id="outter_loading">
        <div id="inner_loading" />
      </div>

      {error && (
        <Paragraph size={500} style={{ marginTop: 20, color: 'indianred' }}>
          {error}
        </Paragraph>
      )}

      {isFinished && (
        <p
          style={{ marginTop: 20, color: 'mediumseagreen', fontSize: 18, fontWeight: 'bold' }}
          className="animate__animated animate__fadeIn"
          id="completed_text">
          Emails have been sent!
        </p>
      )}

      {verifiedContacts && (
        <div style={{ marginTop: 20 }}>
          <Paragraph size={500} className="animate__animated animate__fadeInDown">
            {verifiedContacts} Contacts Verified
          </Paragraph>
          <Paragraph size={500} className="animate__animated animate__fadeInDown animate__delay-1s">
            Processing Uploading to Constant Contact...
          </Paragraph>
        </div>
      )}

      <Reports shouldUpdate={shouldGetReports} />
    </div>
  )
}

const styles = {
  crossIcon: {
    marginLeft: 20,
    cursor: 'pointer',
    position: 'absolute',
    right: -30,
    top: 5
  }
}

const setInitLoadingBar = (bar, outter, fileList) => {
  bar.style.backgroundColor = 'indianred'
  outter.style.display = 'block'
  bar.style.width = '0'
  bar.style.transition = 'all 1.5s ease-in'
  fileList.className = ''
}

const changeLoadingBar = (bar) => {
  bar.style.backgroundColor = 'cornflowerblue'
  bar.style.transition = 'all 12s ease-out'
  bar.style.width = '90%'
}

const finishLoadingBar = (bar) => {
  bar.style.transition = 'all 1.3s ease-out'
  bar.style.backgroundColor = 'mediumseagreen'
  bar.style.width = '100%'
}

const resetLoadingBar = (bar, outter, fileList) => {
  outter.style.display = 'none'
  outter.className = ''
  bar.style.width = '0'
  fileList.className = ''
}

export default Upload
