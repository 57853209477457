import { formatStores } from '../helpers/stores.js'

export const SELECT_SURVEY = id => ({ type: 'SET_CURRENT_SURVEY', payload: Number(id) })

export const SET_LOCATIONS = locations => ({ type: 'SET_ALL_LOCATIONS', payload: locations })

export const SET_SELECTED_LOCATION = location => ({ type:'SET_SELECTED_LOCATION', payload: location })

export const SET_DATE_RANGE = dates => ({ type: 'SET_DATE_RANGE', payload: dates })

export const SET_SURVEY_DATA = (surveyData) => {
    if(surveyData.total_responses !== 0) {
    const allLocations = formatStores(surveyData.surveysSortedByStore)
    const selectedLocation = allLocations[0].store
    return { type: 'SET_SURVEY_DATA', payload: { allLocations, selectedLocation, surveyData } }
    } else return { type: 'SET_SURVEY_DATA', payload: { allLocations: [], selectedLocation: null, surveyData } }
}

export const SET_NOTIFICATION_SURVEYS = bool => ({ type: 'SET_NOTIFICATION_SURVEYS', payload: bool })