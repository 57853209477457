import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading, Text, StarIcon } from 'evergreen-ui'

import { formatPreset } from './DashboardController'
import SideSheets from '../SideSheets/SideSheets.jsx'
import CurrentlyViewing from './CurrentlyViewing.jsx'
import Charts from './Charts/Charts.jsx'
import { DashboardLoader, NoSurveyData } from '../../../Loaders.jsx'

import static_surveys from '../../../helpers/surveys.js'
import { UPDATE_TAB } from '../../../actions/nav.js'
import { getLogoImage, getImageStyles } from '../../../helpers/images.js'

import '../../../styles/Dashboard.css'

const Dashboard = () => {
  const dispatch = useDispatch()
  const {
    currentSurveyId: surveyId,
    surveyData,
    selectedLocation,
    selectedPreset,
    dates,
    getNotificationSurveys,
    isLoading,
    allLocations
  } = useSelector((state) => state.survey)

  //State
  const [singleSurveyResponse, setSingleSurvey] = useState(null)

  //ComponentDidMount
  useEffect(() => {
    dispatch(UPDATE_TAB('dashboard'))
    if (surveyId) setSingleSurvey(null)
  }, [dispatch, surveyId])

  const handleViewSingleSurvey = (id) => {
    const { surveysSortedByStore } = surveyData
    let survey = null
    Object.values(surveysSortedByStore).forEach((responses) => {
      const foundSurvey = responses.find((survey) => survey.id === id)
      if (foundSurvey) return (survey = foundSurvey)
    })
    return setSingleSurvey([survey])
  }

  const selectedSurveys = () => {
    if (surveyData) {
      const { surveysSortedByStore } = surveyData
      if (selectedPreset) return formatPreset(surveysSortedByStore, selectedPreset)
      else if (selectedLocation) return surveysSortedByStore[selectedLocation]
    }
    return null
  }
  const surveysToRender = singleSurveyResponse || selectedSurveys()
  const rank = selectedPreset ? null : getRanking(allLocations, selectedLocation)
  return (
    <div className="dashboard_wrapper">
      <section>
        <DashboardText total={surveyData ? surveyData.total_responses : 'Loading'} surveyId={surveyId} rank={rank} />
        <SideSheets
          survey={surveysToRender}
          allSurveys={selectedSurveys()}
          isLoading={isLoading}
          onChange={() => setSingleSurvey(null)}
          dates={dates}
          selectedLocation={selectedPreset ? selectedPreset.name : selectedLocation}
          surveyId={surveyId}
          allLocations={allLocations}
          viewSingleSurvey={handleViewSingleSurvey}
        />
      </section>
      <div className="survey_wrapper">
        {isLoading ? (
          <DashboardLoader />
        ) : checkMissingData(surveyData, surveysToRender) ? (
          <NoSurveyData dates={dates} />
        ) : (
          <>
            <Heading fontWeight={800} size={800} textTransform="uppercase">
              {static_surveys[surveyId]} SURVEY
            </Heading>
            {singleSurveyResponse ? (
              <Text>Viewing Single Customer Response</Text>
            ) : (
              <Text>
                Viewing {surveysToRender.length} Responses at{' '}
                {selectedPreset ? selectedPreset.locations.length : 1} Location
                {selectedPreset && selectedPreset.locations.length > 1 ? 's' : ''}
              </Text>
            )}
            <CurrentlyViewing
              location={selectedLocation}
              dates={dates}
              selectedPreset={selectedPreset}
              isViewingSingleSurvey={singleSurveyResponse}
              isViewingNotis={getNotificationSurveys}
            />
            <Charts
              surveyData={surveysToRender}
              handleViewSingleSurvey={handleViewSingleSurvey}
              handleReset={() => setSingleSurvey(null)}
              isViewingSingleSurvey={singleSurveyResponse}
              surveyId={surveyId}
              isPrinting={false}
            />
          </>
        )}
      </div>
    </div>
  )
}

const checkMissingData = ({ total_responses }, surveys) =>
  total_responses === 0 || surveys.length === 0

const DashboardText = ({ total, surveyId, rank }) => (
  <div style={{ display: 'flex', marginBottom: 10 }}>
    <img src={getLogoImage(surveyId)} alt="survey_logo" style={getImageStyles(surveyId)} />
    <div>
      <Heading size={700} fontWeight={800}>
        DASHBOARD
      </Heading>
      <p style={{ fontWeight: 'normal', opacity: 0.8 }}>{total} Total Responses</p>
    </div>

    {rank && <div style={{marginLeft: 20}}>
      {rank === 1 ? <Heading size={700} fontWeight={600} color='goldenrod' className="rank_container">
        <StarIcon size={20} className="top_store_icon"/> TOP STORE!
      </Heading> :
      <Heading size={700} fontWeight={600}>
        <StarIcon/> Rank
      </Heading>}
      <p style={{ fontWeight: 'normal', opacity: 0.8 }}>{rank === 1 ? `Rank #${rank}` : `#${rank}`}</p>

    </div>}
  </div>
)

const getRanking = (allLocations, location) => {
  const foundStore = allLocations.find(loc => loc.store === location)
  if(foundStore) return Number(foundStore.rank)
  else return null
}

export default Dashboard
