import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  Pane,
  Heading,
  Button,
  AddIcon,
  TrashIcon,
  Select,
  TextInputField,
  toaster
} from 'evergreen-ui'

import static_surveys from '../../../../helpers/surveys.js'

const MangePreset = ({ isShown, userSurveys, presetToEdit, updatePreset, createPreset, handleClose, handleDelete }) => {
  const surveys = useSelector((state) => state.user.surveys)
  //State
  const [presetSurveyId, setPresetSurveyId] = useState(
    presetToEdit ? presetToEdit.survey_id : userSurveys[0]
  )
  const [presetName, setPresetName] = useState(presetToEdit ? presetToEdit.name : '')
  const [presetLocations, setPresetLocations] = useState(presetToEdit ? presetToEdit.locations : [])

  const handleAddLocation = (loc) => setPresetLocations([...presetLocations, loc])
  const handleRemoveLocation = (loc) =>
    setPresetLocations(presetLocations.filter((location) => location !== loc))

  const onConfirm = () => {
    if(presetName.length === 0) return alert('Please enter a name!')
    if(presetLocations.length === 0) return alert('Please select a location!')
    const updatedPreset = {
      ...presetToEdit,
      name: presetName,
      locations: presetLocations,
      survey_id: presetSurveyId
    }
    if(isCreatingNew) updatedPreset.id = Math.floor(Math.random() * 900000) + 100000
    return isCreatingNew ? createPreset(updatedPreset) : updatePreset(updatedPreset)
  }

  const allLocations = () => {
    let locations = []
    try { locations = surveys.find(({ id }) => presetSurveyId === id).stores }
    catch(err) { toaster.warning('Survey locations not found or survey does not exist.') }
    return locations
  }
  const isCreatingNew = !presetToEdit

  const getDropdowns = () => {
    if(userSurveys.length === 0) return surveys
    else return userSurveys
  }

  return (
    <Dialog
      title={isCreatingNew ? 'Create a Preset' : 'Update Preset'}
      isShown={isShown}
      onCloseComplete={handleClose}
      footer={<Footer isCreating={isCreatingNew} onConfirm={onConfirm} onCancel={handleClose} onDelete={handleDelete} />}>
      <Pane {...paneStyles}>
        {isCreatingNew && <Heading size={400}>Choose a Survey</Heading>}

        {isCreatingNew && (
          <Select
            width={200}
            marginBottom={15}
            value={presetSurveyId}
            onChange={(e) => {
              setPresetLocations([])
              setPresetSurveyId(Number(e.target.value))
            }}>
            {getDropdowns().map(id => (
              <option value={id.toString()} key={id.toString()}>
                {static_surveys[id]}
              </option>
            ))}
          </Select>
        )}

        <TextInputField
          label="Name your Preset"
          placeholder="Preset Name..."
          width="100%"
          marginBottom={30}
          onChange={(e) => setPresetName(e.target.value)}
          value={presetName}
        />

        {allLocations()
          .sort((a, b) => a.localeCompare(b))
          .map((loc) => (
            <Location
              key={loc}
              loc={loc}
              isSelected={presetLocations.includes(loc)}
              handleAdd={handleAddLocation}
              handleRemove={handleRemoveLocation}
            />
          ))}
      </Pane>
    </Dialog>
  )
}

// const compareLocation = (presetLocations, currentLoc) => {
//   const locScores = []
//   presetLocations.forEach(loc => locScores.push(compareTwoStrings(loc, currentLoc)))

//   const closestWord = presetLocations[locScores.indexOf(Math.max(...locScores))]
//   return presetLocations.includes(closestWord)
// }

const Location = ({ loc, isSelected, handleAdd, handleRemove }) => (
  <div key={loc} className="sidemenu_presets_location_container">
    <Heading key={loc} fontWeight={isSelected ? 600 : 200}>
      {loc}
    </Heading>
    {isSelected ? (
      <Button
        iconBefore={TrashIcon}
        appearance="minimal"
        intent="danger"
        onClick={() => handleRemove(loc)}>
        Remove
      </Button>
    ) : (
      <Button iconBefore={AddIcon} appearance="minimal" onClick={() => handleAdd(loc)}>
        Add
      </Button>
    )}
  </div>
)

const Footer = ({ isCreating, onConfirm, onCancel, onDelete }) => (
  <div>
    {!isCreating && (
      <Button intent="danger" appearance="minimal" style={{ position: 'absolute', left: 10 }} onClick={onDelete}>
        Delete Preset
      </Button>
    )}
    <Button appearance="default" marginRight={15} onClick={onCancel}>
      Cancel
    </Button>
    <Button appearance="primary" onClick={onConfirm}>{isCreating ? 'Create' : 'Update'}</Button>
  </div>
)

const paneStyles = {
  height: 'auto',
  minHeight: 500,
  width: '100%',
  backgroundColor: 'white',
  paddingLeft: '20px',
  paddingRight: '20px',
  boxSizing: 'border-box'
}

export default MangePreset
