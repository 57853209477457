import { Table } from 'evergreen-ui'
import StarRatings from 'react-star-ratings'

import { storeList } from '../../../helpers/stores.js'

const RankingTable = ({ sortedData, isPrinting, isUtah }) => (
  <Table marginTop={15}>
    <Table.Head>
      <Table.TextHeaderCell flexBasis={60} flexShrink={0} flexGrow={0}>
        Rank
      </Table.TextHeaderCell>
      {isUtah && (
        <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
          Store #
        </Table.TextHeaderCell>
      )}
      <Table.TextHeaderCell>Store Address</Table.TextHeaderCell>
      <Table.TextHeaderCell>Average Stars</Table.TextHeaderCell>
      <Table.TextHeaderCell>Total Surveys</Table.TextHeaderCell>
    </Table.Head>

    <Table.Body>
      {sortedData.map((store) => (
        <Table.Row value={store.store} key={store.store} style={{ backgroundColor: 'white' }}>
          <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
            {store.rank}
          </Table.TextCell>
          {isUtah && (
            <Table.TextCell flexBasis={60} flexShrink={0} flexGrow={0}>
              {storeList.find((s) => store.store === s.store_address).store_number}
            </Table.TextCell>
          )}
          <Table.TextCell>{store.store}</Table.TextCell>
          <Table.TextCell>
            <StarRatings
              rating={store.netPromoter}
              starRatedColor={isPrinting ? 'darkslategray' : 'gold'}
              numberOfStars={5}
              starDimension="20px"
            />{' '}
            ({store.netPromoter})
          </Table.TextCell>
          <Table.TextCell>{store.surveys}</Table.TextCell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default RankingTable
