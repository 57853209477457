import JiffyLubeLogo from '../resources/JiffyLubeCircle.png'
import GoldenNozzleLogo from '../resources/GoldenNozzle.png'
import FLRobertsLogo from '../resources/FLRoberts.png'

export const getLogoImage = (id) => (id === 5 ? GoldenNozzleLogo : id === 9 ? FLRobertsLogo : JiffyLubeLogo)

export const getImageStyles = (key) => {
    const isGoldeNozzle = key === 5
    return { width: isGoldeNozzle ? 130 : 40, height: 40, marginRight: 8 }
  }
