import { useState } from 'react'
import { useSelector } from 'react-redux'
import { TextInputField, Heading, Paragraph, Button } from 'evergreen-ui'
import dayjs from 'dayjs'

import static_surveys from '../../helpers/surveys.js'
import { handleUpdateLogin, handleUpdatePassword } from '../../api/users.js'

const EditAccount = () => {
  const user = useSelector((state) => state.user)

  const [email, setEmail] = useState(user.email)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [isLoadingEmail, setLoadingEmail] = useState(false)
  const [isLoadingPass, setLoadingPass] = useState(false)

  const updateEmail = async () => {
    setLoadingEmail(true)
    try {
      if (email.length < 4) throw Error('Username is not long enough...')
      const didUpdate = await handleUpdateLogin(email)
      setLoadingEmail(false)
      return alert(didUpdate)
    } catch (err) {
      setLoadingEmail(false)
      alert(err)
    }
  }

  const updatePassword = async () => {
    try {
      if (currentPassword.length < 4 || newPassword.length < 4)
        throw Error('Please enter a longer password...')
      else if (currentPassword === newPassword) throw Error('Passwords are the same...')
      setLoadingPass(true)
      const didUpdate = await handleUpdatePassword(currentPassword, newPassword)
      setCurrentPassword('')
      setNewPassword('')
      setLoadingPass(false)
      return alert(didUpdate)
    } catch (err) {
      setLoadingPass(false)
      alert(err)
    }
  }

  return (
    <div style={{ padding: 20, width: '40vw', minWidth: 600 }}>
      <Heading marginBottom={20} size={800}>
        My Account
      </Heading>
      <TextInputField
        label="Email or Username"
        description="Type in an email address to receive emails from TOGSurveys"
        placeholder="harry@mason.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button appearance="primary" isLoading={isLoadingEmail} onClick={updateEmail}>
        Save
      </Button>

      <Heading marginTop={30} size={800}>
        Your Surveys
      </Heading>
      <Paragraph marginBottom={15}>
        {user.surveys.map((survey) => static_surveys[survey.id]).join(', ')}
      </Paragraph>
      <Paragraph>UserId: {user.user_id}</Paragraph>
      <Paragraph marginBottom={40}>
        Account created on {dayjs(user.createdAt).format('MM/DD/YY')}
      </Paragraph>

      <Heading marginBottom={20} size={800}>
        Password Reset
      </Heading>

      <TextInputField
        label="Current Password"
        description="Type in your current password to reset"
        placeholder="mypassword@321!"
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <TextInputField
        label="New Password"
        description="Type in your new password"
        placeholder="mypassword@321!"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <Button appearance="primary" isLoading={isLoadingPass} onClick={updatePassword}>
        Update Password
      </Button>
    </div>
  )
}

export default EditAccount
