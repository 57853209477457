import {
  Pane,
  Popover,
  Menu,
  Pill,
  Position,
  CrossIcon,
  NotificationsIcon,
  NotificationsUpdatedIcon
} from 'evergreen-ui'
import { Link } from 'react-router-dom'

import static_surveys from '../../helpers/surveys.js'
import { getLogoImage, getImageStyles } from '../../helpers/images.js'

export const NotificationBell = ({ notis, notiCount, handleClickNoti, handleDeleteNoti }) => (
  <Pane>
    <Popover
      trigger="hover"
      position={Position.TOP_RIGHT}
      content={
        <>
          <p className="notifications_header">NOTIFICATIONS</p>
          {notiCount === 0 ? (
            <NoNotifications />
          ) : (
            <Menu>
              <NotificationBoxes
                notis={notis}
                clickSurvey={handleClickNoti}
                deleteNoti={handleDeleteNoti}
              />
            </Menu>
          )}
        </>
      }>
      <div>
        {notiCount !== 0 && (
          <Pill position="absolute" marginTop={-10} marginLeft={-15} color="red">
            {notiCount}
          </Pill>
        )}
        <NotificationsIcon size={22} marginRight={20} color="lightslategray" className={notiCount !== 0 ? "notification_bell" : "notification_bell_none"} />
      </div>
    </Popover>
  </Pane>
)

export const NotificationBoxes = ({ notis, clickSurvey, deleteNoti }) =>
  Object.keys(notis)
    .sort((a, b) => b - a)
    .map((surveyId) => (
      <Menu.Group key={surveyId}>
        <Menu.Item
          style={{ width: '100%', marginTop: 10 }}
          icon="notifications"
          className="notification_link"
          secondaryText={<CrossIcon className="close_icon" onClick={() => deleteNoti(surveyId)} />}>
          <Link to="/account/dashboard">
            <div onClick={() => clickSurvey(surveyId)}>
              <NotificationImage surveyId={Number(surveyId)} />
              <h1>
                {static_surveys[surveyId]} Survey <br />
                <p>Click to view {notis[surveyId]} recent responses</p>
              </h1>
              <p className="new_text">NEW</p>
            </div>
          </Link>
        </Menu.Item>
      </Menu.Group>
    ))

const NoNotifications = () => (
  <div className="no_notifications">
    <NotificationsUpdatedIcon size={48} color="muted" />
    <p>You're Up to Date!</p>
  </div>
)

const NotificationImage = ({ surveyId }) => (
  <img
    src={getLogoImage(Number(surveyId))}
    alt="survey_logo"
    style={getImageStyles(Number(surveyId))}
  />
)
