import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading, Text, Paragraph, Switch, toaster } from 'evergreen-ui'

import { fetchUpdateUserEmails } from '../../api/reports.js'
import { UPDATE_USER_NOTIFICATIONS } from '../../actions/user.js'
import { UPDATE_TAB } from '../../actions/nav.js'

const Reports = () => {
  const dispatch = useDispatch()
  useEffect(() => dispatch(UPDATE_TAB('reports')))
  
  const { weekly_emails, monthly_emails, notification_emails } = useSelector((state) => state.user)
  const [weekly, setWeekly] = useState(weekly_emails)
  const [monthly, setMonthly] = useState(monthly_emails)
  const [notifications, setNotifications] = useState(notification_emails)

  const onToggle = (mode, status) => {
    if(mode === 'weekly') setWeekly(status)
    else if(mode === 'monthly') setMonthly(status)
    else setNotifications(status)

    dispatch(UPDATE_USER_NOTIFICATIONS({[mode + '_emails']: status}))
    fetchUpdateUserEmails(mode, status).then(data => toaster.success(data)).catch(err => toaster.danger(err.toString()))
  }

  return (
    <div style={{ padding: 20 }}>
      <Heading size={700} fontWeight={800} marginBottom={20}>
        YOUR REPORTS
      </Heading>

      <section style={{ display: 'flex', alignItems: 'center' }}>
        <Text size={600} marginRight={10}>
          Weekly Emails
        </Text>
        <Switch checked={weekly} onChange={(e) => onToggle('weekly', Boolean(e.target.checked))} />
      </section>
      <Paragraph>Sends out every Monday</Paragraph>

      <section style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Text size={600} marginRight={10}>
          Monthly Emails
        </Text>
        <Switch checked={monthly} onChange={(e) => onToggle('monthly', Boolean(e.target.checked))} />
      </section>
      <Paragraph>Sends out on the 6th of each month</Paragraph>

      <section style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <Text size={600} marginRight={10}>
          Notification Emails
        </Text>
        <Switch checked={notifications} onChange={(e) => onToggle('notification', Boolean(e.target.checked))} />
      </section>
      <Paragraph>Notifies you every day at 5pm MST of new surveys that have been taken</Paragraph>
    </div>
  )
}

export default Reports
