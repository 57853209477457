const dayjs = require('dayjs')

const surveyState = {
  currentSurveyId: null,
  surveyData: null,
  allLocations: [],
  selectedLocation: null,
  selectedPreset: null,
  dates: { startDate: new Date(dayjs().startOf('month')), endDate: new Date() },
  getNotificationSurveys: false,
  isLoading: true
}

const surveyReducer = (state = surveyState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_SURVEY':
      return {
        ...surveyState,
        dates: state.dates,
        currentSurveyId: action.payload
      }
    case 'SET_SURVEY_DATA':
      return { ...state, ...action.payload, isLoading: false }
    case 'SET_ALL_LOCATIONS':
      return { ...state, allLocations: action.payload }
    case 'SET_SELECTED_LOCATION':
      return { ...state, selectedLocation: action.payload, selectedPreset: null }
    case 'SET_DATE_RANGE':
      return { ...state, dates: action.payload, getNotificationSurveys: false }
    case 'SELECT_PRESET':
      return { ...state, selectedPreset: action.payload, selectedLocation: null }
    case 'SET_NOTIFICATION_SURVEYS':
      return { ...state, getNotificationSurveys: action.payload }
    default:
      return state
  }
}

export default surveyReducer
